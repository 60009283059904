/*
Name: 			theme.css
Written by: 	Okler Themes - (http://www.okler.net)
Theme Version:	6.2.1
*/
html {
	direction: ltr;
	overflow-x: hidden;
	-webkit-box-shadow: none !important;
	box-shadow: none !important;
}

body {
	background-color: #FFF;
	color: #777;
	font-family: "Open Sans", Arial, sans-serif;
	font-size: 14px;
	line-height: 22px;
	margin: 0;
}

body a {
	outline: none !important;
}

.body {
	background-color: #FFF;
}

html.safari .body {
	overflow-x: hidden;
}

li {
	line-height: 24px;
}

/* Responsive */
@media (max-width: 575px) {
	body {
		font-size: 13px;
	}
}
/* Header */
#header {
	position: relative;
	z-index: 1030;
}

#header .header-body {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	background: #FFF;
	-webkit-transition: min-height 0.3s ease;
	transition: min-height 0.3s ease;
	width: 100%;
	border-top: 3px solid #EDEDED;
	border-bottom: 1px solid transparent;
	z-index: 1001;
}

#header .header-container {
	position: relative;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-flow: row wrap;
	flex-flow: row wrap;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-transition: ease height 300ms;
	transition: ease height 300ms;
}

@media (max-width: 991px) {
	#header .header-container:not(.container) .header-nav-main {
		padding: 0 0.75rem;
	}
}

#header .container {
	position: relative;
}

@media (max-width: 767px) {
	#header .container {
		width: 100%;
	}
}

#header .header-row {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-flex: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-ms-flex-item-align: stretch;
	align-self: stretch;
	max-height: 100%;
}

#header .header-column {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-item-align: stretch;
	align-self: stretch;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-flex: 1;
	-ms-flex-positive: 1;
	flex-grow: 1;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
}

#header .header-column .header-row {
	-webkit-box-pack: inherit;
	-ms-flex-pack: inherit;
	justify-content: inherit;
}

#header .header-column .header-extra-info {
	list-style: outside none none;
	margin: 0;
	padding: 0;
}

#header .header-column .header-extra-info li {
	display: -webkit-inline-box;
	display: -ms-inline-flexbox;
	display: inline-flex;
	margin-left: 25px;
}

#header .header-column .header-extra-info li .feature-box p {
	margin: 0;
}

#header .header-column .header-extra-info li .feature-box small {
	position: relative;
	top: -4px;
	font-size: 80%;
}

#header.header-no-min-height .header-body {
	min-height: 0 !important;
}

#header .header-top {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	position: relative;
	background-color: #f4f4f4;
	border-bottom: 1px solid #ededed;
	min-height: 51px;
	z-index: 4;
}

#header .header-top::after {
	clear: both;
	content: "";
	display: block;
}

#header .header-top p {
	font-size: 0.9em;
	margin: 0;
	padding: 0;
}

#header .header-top p .fa, #header .header-top p .fas, #header .header-top p .far, #header .header-top p .fal, #header .header-top p .fab, #header .header-top p .icons {
	position: relative;
	top: 1px;
}

#header .header-top .header-search {
	margin-left: 10px;
	margin-right: 0;
}

#header .header-top .header-nav-top {
	float: left;
	margin-top: 0;
	margin-left: 0;
	margin-right: 10px;
}

#header .header-top.header-top-no-min-height {
	min-height: 0;
}

#header .header-top.header-top-colored {
	border-top: 5px solid #CCC;
	border-bottom: 0;
	color: #FFF;
}

#header .header-top.header-top-colored p, #header .header-top.header-top-colored a, #header .header-top.header-top-colored span {
	color: #FFF;
}

#header .header-top.header-top-colored .form-control {
	border: 0;
}

#header .header-top.header-top-style-2 {
	background: #f6f6f6;
}

#header .header-top.header-top-style-4 {
	background: #E4E6ED;
	border-bottom: 0;
}

#header .header-logo {
	margin: 16px 0;
	margin: 1rem 0;
}

#header .header-logo img {
	-webkit-transition: all 0.3s ease;
	transition: all 0.3s ease;
	position: relative;
	top: 0;
}

#header .header-nav {
	padding: 16px 0;
	padding: 1rem 0;
	min-height: 70px;
}

#header .header-nav-top {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-item-align: stretch;
	align-self: stretch;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
}

#header .header-nav-top .nav > li > a, #header .header-nav-top .nav > li > span {
	color: #999;
	font-size: 0.9em;
	padding: 6px 10px;
	display: inline-block;
}

#header .header-nav-top .nav > li > a .fa, #header .header-nav-top .nav > li > a .fas, #header .header-nav-top .nav > li > a .far, #header .header-nav-top .nav > li > a .fal, #header .header-nav-top .nav > li > a .fab, #header .header-nav-top .nav > li > a .icons, #header .header-nav-top .nav > li > span .fa, #header .header-nav-top .nav > li > span .fas, #header .header-nav-top .nav > li > span .far, #header .header-nav-top .nav > li > span .fal, #header .header-nav-top .nav > li > span .fab, #header .header-nav-top .nav > li > span .icons {
	margin-right: 3px;
}

#header .header-nav-top .nav > li > a {
	text-decoration: none;
}

#header .header-nav-top .nav > li > a:hover, #header .header-nav-top .nav > li > a a:focus {
	background: #EEE;
}

#header .header-nav-top .nav > li.nav-item.dropdown.show .nav-link {
	background: #EEE;
}

#header .header-nav-top .nav > li.open > .dropdown-menu-toggle {
	border-radius: 4px 4px 0 0;
}

#header .header-nav-top .dropdown .dropdown-item {
	font-size: 0.8em;
}

#header .header-nav-top .dropdown-menu {
	border-color: #EEE;
	margin-top: -2px;
	border-radius: 4px 0 4px 4px;
	padding: 0;
}

#header .header-nav-top .dropdown-menu a {
	color: #999;
	padding: 6px 12px;
	font-size: 0.9em;
}

#header .header-nav-top .flag {
	margin-right: 2px;
	position: relative;
	top: -1px;
}

#header .header-search {
	margin-left: 16px;
	margin-left: 1rem;
}

#header .header-search .form-control {
	border-radius: 20px;
	font-size: 0.9em;
	height: 34px;
	margin: 0;
	padding: 6px 12px;
	-webkit-transition: width 0.3s ease;
	transition: width 0.3s ease;
	-webkit-perspective: 1000px;
	perspective: 1000px;
	width: 170px;
}

#header .header-search .btn-light {
	background: transparent;
	border: 0 none;
	color: #CCC;
	position: absolute;
	right: 0;
	top: 0;
	z-index: 3;
}

#header .header-search .btn-light:hover {
	color: #000;
}

#header .header-social-icons {
	margin-left: 16px;
	margin-left: 1rem;
}

#header .header-btn-collapse-nav {
	background: #CCC;
	color: #FFF;
	display: none;
	float: right;
	margin: 0 0 0 16px;
	margin: 0 0 0 1rem;
}

#header.header-narrow .header-body {
	min-height: 0;
}

#header.header-narrow .header-logo {
	margin-top: 0;
	margin-bottom: 0;
}

#header.header-narrow .header-nav {
	padding-top: 0;
	padding-bottom: 0;
}

#header.header-no-border-bottom .header-body {
	padding-bottom: 0 !important;
	border-bottom: 0 !important;
}

@media (max-width: 767px) {
	#header.has-nav-bar .header-search {
		display: block !important;
		visibility: hidden;
	}
}

html:not(.boxed) #header.header-full-width .header-container {
	width: 100%;
	max-width: none;
}

html #header.header-transparent {
	min-height: 0 !important;
	width: 100%;
	position: absolute;
}

html #header.header-transparent .header-body {
	position: relative;
	top: 0;
	background: transparent;
	border-top: 0;
	border-bottom: 0;
	-webkit-box-shadow: none;
	box-shadow: none;
}

html #header.header-transparent .header-body:before {
	content: "";
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background: #FFF;
	opacity: 0;
	border-bottom: 1px solid #f1f1f1;
	-webkit-transition: opacity 0.3s ease;
	transition: opacity 0.3s ease;
	-webkit-perspective: 1000px;
	perspective: 1000px;
}

html.sticky-header-enabled #header.header-transparent:not(.header-transparent-not-fixed) .header-body {
	position: fixed;
}

html.sticky-header-active #header.header-transparent .header-body {
	border-bottom: 0;
	-webkit-box-shadow: none;
	box-shadow: none;
}

html.sticky-header-active #header.header-transparent .header-body:before {
	opacity: 1;
}

html #header.header-semi-transparent, html #header.header-semi-transparent-light {
	min-height: 0 !important;
	width: 100%;
	position: absolute;
}

html #header.header-semi-transparent .header-body, html #header.header-semi-transparent-light .header-body {
	position: relative;
	top: 0;
	background: transparent;
	border-top: 0;
	border-bottom: 0;
	-webkit-box-shadow: none;
	box-shadow: none;
}

html #header.header-semi-transparent .header-body:before, html #header.header-semi-transparent-light .header-body:before {
	content: "";
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background: #000;
	border-bottom: 0;
	opacity: 0.3;
	-webkit-transition: opacity 0.3s ease;
	transition: opacity 0.3s ease;
	-webkit-perspective: 1000px;
	perspective: 1000px;
}

html.sticky-header-enabled #header.header-semi-transparent .header-body {
	position: fixed;
}

html.sticky-header-active #header.header-semi-transparent .header-body {
	border-bottom: 0;
	-webkit-box-shadow: none;
	box-shadow: none;
}

html.sticky-header-active #header.header-semi-transparent .header-body:before {
	opacity: 0.8;
}

html #header.header-semi-transparent-light .header-body:before {
	opacity: 0.1;
	background: #FFF;
}

html.sticky-header-enabled #header.header-semi-transparent-light .header-body {
	position: fixed;
}

html.sticky-header-active #header.header-semi-transparent-light .header-body {
	border-bottom: 0;
	-webkit-box-shadow: none;
	box-shadow: none;
}

html.sticky-header-active #header.header-semi-transparent-light .header-body:before {
	border-bottom: 1px solid #f1f1f1;
	opacity: 0.9;
}

html #header.header-transparent-bottom-border .header-body {
	border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

html.sticky-header-active #header .header-body {
	position: fixed;
	border-bottom-color: #e9e9e9;
	-webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
	box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
}

html:not(.sticky-header-active) #header.header-transparent-sticky-deactive .header-body:before {
	background: transparent !important;
}

html:not(.sticky-header-active) #header.header-transparent-sticky-deactive.header-transparent .header-nav-main:after, html:not(.sticky-header-active) #header.header-transparent-sticky-deactive.header-semi-transparent .header-nav-main:after, html:not(.sticky-header-active) #header.header-transparent-sticky-deactive.header-semi-transparent-light .header-nav-main:after {
	background: transparent !important;
}

/* Header Nav Main */
@media (min-width: 992px) {
	#header .header-nav-main nav {
		display: -webkit-box !important;
		display: -ms-flexbox !important;
		display: flex !important;
	}

	#header .header-nav-main nav > ul > li {
		margin-left: 2px;
	}

	#header .header-nav-main nav > ul > li > a {
		display: -webkit-inline-box;
		display: -ms-inline-flexbox;
		display: inline-flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		white-space: normal;
		border-radius: 4px;
		color: #CCC;
		font-size: 12px;
		font-style: normal;
		font-weight: 700;
		padding: 0.5rem 0.9rem;
		text-transform: uppercase;
	}

	#header .header-nav-main nav > ul > li > a:active {
		background-color: transparent;
		text-decoration: none;
		color: #CCC;
	}

	#header .header-nav-main nav > ul > li > a.dropdown-toggle .fa-caret-down {
		display: none;
	}

	#header .header-nav-main nav > ul > li > a.dropdown-toggle:after {
		border-color: #CCC transparent transparent transparent;
		border-width: .24rem;
		margin-left: .255em;
		margin-right: 0;
	}

	#header .header-nav-main nav > ul > li > a.active {
		background: #CCC;
		color: #FFF;
	}

	#header .header-nav-main nav > ul > li > a.active.dropdown-toggle:after {
		border-color: #FFF transparent transparent transparent;
	}

	#header .header-nav-main nav > ul > li.open > a, #header .header-nav-main nav > ul > li:hover > a {
		background: #CCC;
		color: #FFF;
	}

	#header .header-nav-main nav > ul > li.dropdown .dropdown-menu {
		top: -10000px;
		display: block;
		opacity: 0;
		left: auto;
		border-radius: 0 4px 4px;
		border: 0;
		border-top: 3px solid #CCC;
		-webkit-box-shadow: 0 20px 45px rgba(0, 0, 0, 0.08);
		box-shadow: 0 20px 45px rgba(0, 0, 0, 0.08);
		margin: 0;
		min-width: 200px;
		padding: 5px;
		text-align: left;
	}

	#header .header-nav-main nav > ul > li.dropdown .dropdown-menu li a {
		border-bottom: 1px solid #f4f4f4;
		color: #777;
		font-size: 0.8em;
		font-weight: 400;
		padding: 6px 20px 6px 8px;
		position: relative;
		text-transform: none;
	}

	#header .header-nav-main nav > ul > li.dropdown .dropdown-menu li a:hover, #header .header-nav-main nav > ul > li.dropdown .dropdown-menu li a:focus, #header .header-nav-main nav > ul > li.dropdown .dropdown-menu li a.active, #header .header-nav-main nav > ul > li.dropdown .dropdown-menu li a:active {
		background-color: #f8f9fa;
	}

	#header .header-nav-main nav > ul > li.dropdown .dropdown-menu li.dropdown-submenu {
		position: relative;
	}

	#header .header-nav-main nav > ul > li.dropdown .dropdown-menu li.dropdown-submenu > a .fa-caret-down {
		display: none;
	}

	#header .header-nav-main nav > ul > li.dropdown .dropdown-menu li.dropdown-submenu > a:after {
		border-color: transparent transparent transparent #CCC;
		border-style: solid;
		border-width: 4px 0 4px 4px;
		content: " ";
		position: absolute;
		top: 50%;
		right: 10px;
		-webkit-transform: translateY(-50%);
		transform: translateY(-50%);
	}

	#header .header-nav-main nav > ul > li.dropdown .dropdown-menu li.dropdown-submenu > .dropdown-menu {
		left: 100%;
		display: block;
		margin-top: -8px;
		margin-left: -1px;
		border-radius: 4px;
		opacity: 0;
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}

	#header .header-nav-main nav > ul > li.dropdown .dropdown-menu li.dropdown-submenu:hover > .dropdown-menu {
		top: 0;
		opacity: 1;
	}

	#header .header-nav-main nav > ul > li.dropdown .dropdown-menu li.dropdown-submenu.dropdown-reverse > a:after {
		border-color: transparent #CCC transparent transparent;
		border-width: 4px 4px 4px 4px;
	}

	#header .header-nav-main nav > ul > li.dropdown .dropdown-menu li.dropdown-submenu.dropdown-reverse > .dropdown-menu {
		left: auto;
		right: 100%;
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}

	#header .header-nav-main nav > ul > li.dropdown .dropdown-menu li:last-child a {
		border-bottom: 0;
	}

	#header .header-nav-main nav > ul > li.dropdown.open > a, #header .header-nav-main nav > ul > li.dropdown:hover > a {
		border-radius: 4px 4px 0 0;
		position: relative;
	}

	#header .header-nav-main nav > ul > li.dropdown.open > a:before, #header .header-nav-main nav > ul > li.dropdown:hover > a:before {
		content: '';
		display: block;
		position: absolute;
		left: 0;
		right: 0;
		bottom: -3px;
		border-bottom: 5px solid #CCC;
	}

	#header .header-nav-main nav > ul > li.dropdown.open > .dropdown-menu, #header .header-nav-main nav > ul > li.dropdown:hover > .dropdown-menu {
		top: auto;
		display: block;
		opacity: 1;
	}

	#header .header-nav-main nav > ul > li.dropdown-mega {
		position: static;
	}

	#header .header-nav-main nav > ul > li.dropdown-mega > .dropdown-menu {
		border-radius: 4px;
		left: 15px;
		right: 15px;
		width: auto;
	}

	#header .header-nav-main nav > ul > li.dropdown-mega .dropdown-mega-content {
		padding: 1.6rem;
	}

	#header .header-nav-main nav > ul > li.dropdown-mega .dropdown-mega-sub-title {
		color: #333333;
		display: block;
		font-size: 0.9em;
		font-weight: 600;
		margin-top: 20px;
		padding-bottom: 10px;
		text-transform: uppercase;
	}

	#header .header-nav-main nav > ul > li.dropdown-mega .dropdown-mega-sub-title:first-child {
		margin-top: 0;
	}

	#header .header-nav-main nav > ul > li.dropdown-mega .dropdown-mega-sub-nav {
		list-style: none;
		padding: 0;
		margin: 0;
	}

	#header .header-nav-main nav > ul > li.dropdown-mega .dropdown-mega-sub-nav > li > a {
		border: 0 none;
		border-radius: 4px;
		color: #777;
		display: block;
		font-size: 0.8em;
		font-weight: normal;
		margin: 0 0 3px -8px;
		padding: 3px 8px;
		text-shadow: none;
		text-transform: none;
		text-decoration: none;
	}

	#header .header-nav-main nav > ul > li.dropdown-mega .dropdown-mega-sub-nav > li:hover > a {
		background: #f4f4f4;
	}

	#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-shop > .dropdown-item {
		padding: 0.5rem 0.8rem;
	}

	#header .header-nav-main.header-nav-main-no-arrows nav > ul > li a.dropdown-toggle {
		padding-left: 16px !important;
		padding-right: 16px !important;
	}

	#header .header-nav-main.header-nav-main-no-arrows nav > ul > li a.dropdown-toggle:after {
		display: none;
	}

	#header .header-nav-main.header-nav-main-square nav > ul > li > a {
		border-radius: 0 !important;
	}

	#header .header-nav-main.header-nav-main-square nav > ul > li.dropdown .dropdown-menu {
		border-radius: 0;
	}

	#header .header-nav-main.header-nav-main-square nav > ul > li.dropdown .dropdown-menu li.dropdown-submenu > .dropdown-menu {
		border-radius: 0;
	}

	#header .header-nav-main.header-nav-main-square nav > ul > li.dropdown-mega > .dropdown-menu {
		border-radius: 0;
	}

	#header .header-nav-main.header-nav-main-square nav > ul > li.dropdown-mega .dropdown-mega-sub-nav > li > a {
		border-radius: 0;
	}

	#header .header-nav-main.header-nav-main-dark nav > ul > li > a {
		color: #444;
	}

	#header .header-nav-main a > .thumb-info-preview {
		position: absolute;
		display: block;
		left: 100%;
		opacity: 0;
		border: 0;
		padding-left: 10px;
		background: transparent;
		overflow: visible;
		margin-top: 15px;
		top: -10000px;
		-webkit-transition: opacity .2s ease-out, -webkit-transform .2s ease-out;
		transition: opacity .2s ease-out, -webkit-transform .2s ease-out;
		transition: transform .2s ease-out, opacity .2s ease-out;
		transition: transform .2s ease-out, opacity .2s ease-out, -webkit-transform .2s ease-out;
		-webkit-transform: translate3d(-20px, 0, 0);
		transform: translate3d(-20px, 0, 0);
	}

	#header .header-nav-main a > .thumb-info-preview .thumb-info-wrapper {
		background: #FFF;
		display: block;
		border-radius: 4px;
		border: 0;
		-webkit-box-shadow: 0 20px 45px rgba(0, 0, 0, 0.08);
		box-shadow: 0 20px 45px rgba(0, 0, 0, 0.08);
		margin: 0;
		padding: 4px;
		text-align: left;
		width: 190px;
	}

	#header .header-nav-main a > .thumb-info-preview .thumb-info-image {
		-webkit-transition: all 6s linear 0s;
		transition: all 6s linear 0s;
		width: 182px;
		height: 136px;
		min-height: 0;
	}

	#header .header-nav-main a:hover > .thumb-info-preview {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
		top: 0;
		opacity: 1;
		margin-top: -5px;
	}

	#header .header-nav-main .dropdown-reverse a > .thumb-info-preview {
		-webkit-transform: translate3d(20px, 0, 0);
		transform: translate3d(20px, 0, 0);
		right: 100%;
		left: auto;
		padding-left: 0;
		margin-right: 10px;
	}

	#header .header-nav-main .dropdown-reverse a:hover > .thumb-info-preview {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}

	#header .header-nav {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-flex: 1;
		-ms-flex-positive: 1;
		flex-grow: 1;
		-webkit-box-pack: end;
		-ms-flex-pack: end;
		justify-content: flex-end;
		-ms-flex-item-align: stretch;
		align-self: stretch;
	}

	#header .header-nav.header-nav-stripe {
		padding: 0;
	}

	#header .header-nav.header-nav-stripe .header-nav-main {
		-ms-flex-item-align: stretch;
		align-self: stretch;
		margin-top: -1px;
		min-height: 0;
	}

	#header .header-nav.header-nav-stripe nav {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
	}

	#header .header-nav.header-nav-stripe nav > ul > li {
		display: -webkit-inline-box;
		display: -ms-inline-flexbox;
		display: inline-flex;
		-ms-flex-item-align: stretch;
		align-self: stretch;
	}

	#header .header-nav.header-nav-stripe nav > ul > li > a {
		background: transparent;
		padding: 0 .9rem;
		margin: 1px 0 0;
		height: 100%;
		color: #444;
		min-height: 90px;
	}

	#header .header-nav.header-nav-stripe nav > ul > li > a.dropdown-toggle:after {
		border-color: #444 transparent transparent transparent;
	}

	#header .header-nav.header-nav-stripe nav > ul > li > a.active {
		color: #CCC;
		background: transparent;
	}

	#header .header-nav.header-nav-stripe nav > ul > li > a.active.dropdown-toggle:after {
		border-color: #CCC transparent transparent transparent;
	}

	#header .header-nav.header-nav-stripe nav > ul > li:hover > a, #header .header-nav.header-nav-stripe nav > ul > li:hover > a.active, #header .header-nav.header-nav-stripe nav > ul > li.open > a, #header .header-nav.header-nav-stripe nav > ul > li.open > a.active {
		color: #FFF;
		padding-bottom: 0;
	}

	#header .header-nav.header-nav-stripe nav > ul > li:hover > a.dropdown-toggle:after, #header .header-nav.header-nav-stripe nav > ul > li:hover > a.active.dropdown-toggle:after, #header .header-nav.header-nav-stripe nav > ul > li.open > a.dropdown-toggle:after, #header .header-nav.header-nav-stripe nav > ul > li.open > a.active.dropdown-toggle:after {
		border-color: #FFF transparent transparent transparent;
	}

	#header .header-nav.header-nav-stripe nav > ul > li.dropdown.open > a:before, #header .header-nav.header-nav-stripe nav > ul > li.dropdown:hover > a:before {
		content: none;
	}

	#header .header-nav.header-nav-stripe nav > ul > li.dropdown.open > .dropdown-menu, #header .header-nav.header-nav-stripe nav > ul > li.dropdown:hover > .dropdown-menu {
		top: 100%;
		left: 0;
		margin-top: 1px;
	}

	#header .header-nav.header-nav-stripe.header-nav-main-dark nav > ul > li:hover > a {
		color: #FFF !important;
	}

	#header .header-nav.header-nav-top-line {
		padding: 0;
	}

	#header .header-nav.header-nav-top-line .header-nav-main {
		-ms-flex-item-align: stretch;
		align-self: stretch;
		min-height: 0;
		margin-top: 0;
	}

	#header .header-nav.header-nav-top-line nav > ul > li > a, #header .header-nav.header-nav-top-line nav > ul > li:hover > a {
		position: relative;
		background: transparent !important;
		color: #444;
		padding: 0 .9rem;
		margin: 1px 0 0;
		min-height: 70px;
		height: 100%;
	}

	#header .header-nav.header-nav-top-line nav > ul > li > a:before, #header .header-nav.header-nav-top-line nav > ul > li:hover > a:before {
		content: "";
		position: absolute;
		background: #CCC;
		width: 100%;
		height: 3px;
		top: -2px;
		left: 0;
		opacity: 0;
	}

	#header .header-nav.header-nav-top-line nav > ul > li:hover > a:before, #header .header-nav.header-nav-top-line nav > ul > li.open > a:before {
		opacity: 1;
	}

	#header .header-nav.header-nav-top-line nav > ul > li > a.dropdown-toggle:after {
		border-color: #444 transparent transparent transparent;
	}

	#header .header-nav.header-nav-top-line nav > ul > li > a.active {
		color: #CCC;
		background: transparent;
	}

	#header .header-nav.header-nav-top-line nav > ul > li > a.active:before {
		opacity: 1;
	}

	#header .header-nav.header-nav-top-line nav > ul > li > a.active.dropdown-toggle:after {
		border-color: #CCC transparent transparent transparent;
	}

	#header .header-nav.header-nav-top-line nav > ul > li.dropdown > a:before {
		border-bottom: 0;
	}

	#header .header-nav.header-nav-top-line nav > ul > li.dropdown.open > .dropdown-menu, #header .header-nav.header-nav-top-line nav > ul > li.dropdown:hover > .dropdown-menu {
		margin-top: 0;
	}

	#header .header-nav.header-nav-dark-dropdown {
		padding: 0;
	}

	#header .header-nav.header-nav-dark-dropdown .header-nav-main {
		-ms-flex-item-align: stretch;
		align-self: stretch;
		min-height: 0;
		margin-top: 0;
	}

	#header .header-nav.header-nav-dark-dropdown nav > ul > li > a, #header .header-nav.header-nav-dark-dropdown nav > ul > li:hover > a {
		background: transparent !important;
		color: #444;
		margin: 1px 0 0;
		min-height: 70px;
		height: 100%;
		padding: 0 .9rem;
	}

	#header .header-nav.header-nav-dark-dropdown nav > ul > li > a.dropdown-toggle:after {
		border-color: #444 transparent transparent transparent;
	}

	#header .header-nav.header-nav-dark-dropdown nav > ul > li > a.active {
		color: #CCC;
	}

	#header .header-nav.header-nav-dark-dropdown nav > ul > li > a.active.dropdown-toggle:after {
		color: #CCC;
	}

	#header .header-nav.header-nav-dark-dropdown nav > ul > li.dropdown > a:before {
		border-bottom: 0;
	}

	#header .header-nav.header-nav-dark-dropdown nav > ul > li.dropdown li a {
		border-bottom-color: #2a2a2a;
	}

	#header .header-nav.header-nav-dark-dropdown nav > ul > li.dropdown .dropdown-menu {
		background: #1e1e1e;
		margin-top: 0;
	}

	#header .header-nav.header-nav-dark-dropdown nav > ul > li.dropdown .dropdown-menu > li > a {
		color: #969696;
	}

	#header .header-nav.header-nav-dark-dropdown nav > ul > li.dropdown .dropdown-menu > li > a:hover, #header .header-nav.header-nav-dark-dropdown nav > ul > li.dropdown .dropdown-menu > li > a:focus {
		background: #282828;
	}

	#header .header-nav.header-nav-dark-dropdown nav > ul > li.dropdown.dropdown-mega .dropdown-mega-sub-title {
		color: #ababab;
	}

	#header .header-nav.header-nav-dark-dropdown nav > ul > li.dropdown.dropdown-mega .dropdown-mega-sub-nav > li:hover > a {
		background: #282828;
	}

	#header .header-nav-main {
		display: -webkit-box !important;
		display: -ms-flexbox !important;
		display: flex !important;
		height: auto !important;
	}

	#header .header-nav-bar {
		background: #F4F4F4;
		z-index: 1;
	}
}
/* Header Narrow */
@media (min-width: 992px) {
	#header.header-narrow .header-nav.header-nav-stripe nav > ul > li > a, #header.header-narrow .header-nav.header-nav-dark-dropdown nav > ul > li > a, #header.header-narrow .header-nav.header-nav-top-line nav > ul > li > a {
		min-height: 70px;
	}
}

@media (min-width: 992px) {
	#header .header-nav-main.header-nav-main-light nav > ul > li > a {
		color: #FFF;
	}

	#header .header-nav-main.header-nav-main-light nav > ul > li > a.active {
		color: #CCC;
		background: #FFF;
	}

	#header .header-nav-main.header-nav-main-light nav > ul > li > a.dropdown-toggle:after {
		border-color: #FFF transparent transparent transparent;
	}

	#header .header-nav-main.header-nav-main-light nav > ul > li.open > a, #header .header-nav-main.header-nav-main-light nav > ul > li.active > a, #header .header-nav-main.header-nav-main-light nav > ul > li:hover > a {
		background: #FFF;
	}

	#header .header-nav-main.header-nav-main-light nav > ul > li.open > a.dropdown-toggle:after, #header .header-nav-main.header-nav-main-light nav > ul > li.active > a.dropdown-toggle:after, #header .header-nav-main.header-nav-main-light nav > ul > li:hover > a.dropdown-toggle:after {
		border-color: #CCC transparent transparent transparent;
	}

	#header .header-nav-main.header-nav-main-light nav > ul > li.open > .dropdown-menu, #header .header-nav-main.header-nav-main-light nav > ul > li.active > .dropdown-menu, #header .header-nav-main.header-nav-main-light nav > ul > li:hover > .dropdown-menu {
		border-top-color: #FFF;
		-webkit-box-shadow: 0 20px 25px rgba(0, 0, 0, 0.05);
		box-shadow: 0 20px 25px rgba(0, 0, 0, 0.05);
	}

	#header .header-nav-main.header-nav-main-light nav > ul > li.open > .dropdown-menu .dropdown-submenu:hover > .dropdown-menu, #header .header-nav-main.header-nav-main-light nav > ul > li.active > .dropdown-menu .dropdown-submenu:hover > .dropdown-menu, #header .header-nav-main.header-nav-main-light nav > ul > li:hover > .dropdown-menu .dropdown-submenu:hover > .dropdown-menu {
		border-top-color: #FFF;
	}

	#header .header-nav-main.header-nav-main-light nav > ul > li.active > a {
		background: #FFF;
	}

	#header .header-nav-main.header-nav-main-light nav > ul > li.active > a.dropdown-toggle:after {
		border-color: #CCC transparent transparent transparent;
	}

	#header .header-nav-main.header-nav-main-light nav > ul > li.dropdown.open > a:before, #header .header-nav-main.header-nav-main-light nav > ul > li.dropdown:hover > a:before {
		border-bottom-color: #FFF;
	}

	#header .header-nav-main.header-nav-main-light .dropdown-menu > li > a:hover, #header .header-nav-main.header-nav-main-light .dropdown-menu > li > a:focus {
		background: #f5f5f5;
	}
}

@media (min-width: 992px) {
	#header .header-nav-main-effect-1 nav > ul > li.dropdown .dropdown-menu li a, #header .header-nav-main-effect-1 nav > ul > li.dropdown .dropdown-mega-sub-nav li a {
		-webkit-transition: -webkit-transform .2s ease-out;
		transition: -webkit-transform .2s ease-out;
		transition: transform .2s ease-out;
		transition: transform .2s ease-out, -webkit-transform .2s ease-out;
		-webkit-transform: translate3d(0, -5px, 0);
		transform: translate3d(0, -5px, 0);
	}

	#header .header-nav-main-effect-1 nav > ul > li.dropdown:hover > .dropdown-menu li a, #header .header-nav-main-effect-1 nav > ul > li.dropdown:hover .dropdown-mega-sub-nav li a {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}

	#header .header-nav-main-effect-1 nav > ul > li.dropdown .dropdown-menu {
		-webkit-transition: -webkit-transform .2s ease-out;
		transition: -webkit-transform .2s ease-out;
		transition: transform .2s ease-out;
		transition: transform .2s ease-out, -webkit-transform .2s ease-out;
		-webkit-transform: translate3d(0, -5px, 0);
		transform: translate3d(0, -5px, 0);
	}

	#header .header-nav-main-effect-1 nav > ul > li.dropdown:hover > .dropdown-menu {
		-webkit-transform: translate3d(0, 2px, 0);
		transform: translate3d(0, 2px, 0);
	}

	#header .header-nav.header-nav-stripe .header-nav-main-effect-1 nav > ul > li.dropdown:hover > .dropdown-menu, #header .header-nav.header-nav-top-line .header-nav-main-effect-1 nav > ul > li.dropdown:hover > .dropdown-menu, #header .header-nav.header-nav-dark-dropdown .header-nav-main-effect-1 nav > ul > li.dropdown:hover > .dropdown-menu {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}

@media (min-width: 992px) {
	#header .header-nav-main-effect-2 nav > ul > li.dropdown .dropdown-menu {
		-webkit-transition: opacity .2s ease-out, -webkit-transform .2s ease-out;
		transition: opacity .2s ease-out, -webkit-transform .2s ease-out;
		transition: transform .2s ease-out, opacity .2s ease-out;
		transition: transform .2s ease-out, opacity .2s ease-out, -webkit-transform .2s ease-out;
		-webkit-transform: translate3d(0, -5px, 0);
		transform: translate3d(0, -5px, 0);
		opacity: 0;
	}

	#header .header-nav-main-effect-2 nav > ul > li.dropdown:hover > .dropdown-menu {
		-webkit-transform: translate3d(0, -1px, 0);
		transform: translate3d(0, -1px, 0);
		opacity: 1;
	}
}

@media (min-width: 992px) {
	#header .header-nav-main-effect-3 nav > ul > li.dropdown .dropdown-menu {
		-webkit-transition: -webkit-transform .2s ease-out;
		transition: -webkit-transform .2s ease-out;
		transition: transform .2s ease-out;
		transition: transform .2s ease-out, -webkit-transform .2s ease-out;
		-webkit-transform: translate3d(0, 10px, 0);
		transform: translate3d(0, 10px, 0);
	}

	#header .header-nav-main-effect-3 nav > ul > li.dropdown:hover > .dropdown-menu {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
	}
}

@media (min-width: 992px) {
	#header .header-nav-main-effect-4 nav > ul > li.dropdown .dropdown-menu {
		-webkit-transition: opacity .2s ease-out, -webkit-transform .2s ease-out;
		transition: opacity .2s ease-out, -webkit-transform .2s ease-out;
		transition: transform .2s ease-out, opacity .2s ease-out;
		transition: transform .2s ease-out, opacity .2s ease-out, -webkit-transform .2s ease-out;
		-webkit-transform: translate3d(-20px, 0, 0);
		transform: translate3d(-20px, 0, 0);
		opacity: 0;
	}

	#header .header-nav-main-effect-4 nav > ul > li.dropdown .dropdown-menu .dropdown-submenu.dropdown-reverse > .dropdown-menu {
		-webkit-transition: opacity .2s ease-out, -webkit-transform .2s ease-out;
		transition: opacity .2s ease-out, -webkit-transform .2s ease-out;
		transition: transform .2s ease-out, opacity .2s ease-out;
		transition: transform .2s ease-out, opacity .2s ease-out, -webkit-transform .2s ease-out;
		-webkit-transform: translate3d(20px, 0, 0);
		transform: translate3d(20px, 0, 0);
		left: auto;
		right: 100%;
		opacity: 0;
	}

	#header .header-nav-main-effect-4 nav > ul > li.dropdown:hover > .dropdown-menu {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
		opacity: 1;
	}
}

@media (min-width: 992px) {
	#header .header-nav-main-sub-effect-1 nav > ul > li.dropdown .dropdown-menu li.dropdown-submenu > .dropdown-menu {
		-webkit-transition: opacity .2s ease-out, -webkit-transform .2s ease-out;
		transition: opacity .2s ease-out, -webkit-transform .2s ease-out;
		transition: transform .2s ease-out, opacity .2s ease-out;
		transition: transform .2s ease-out, opacity .2s ease-out, -webkit-transform .2s ease-out;
		-webkit-transform: translate3d(-20px, 0, 0);
		transform: translate3d(-20px, 0, 0);
		opacity: 0;
	}

	#header .header-nav-main-sub-effect-1 nav > ul > li.dropdown .dropdown-menu li.dropdown-submenu:hover > .dropdown-menu {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
		opacity: 1;
	}

	#header .header-nav-main-sub-effect-1 nav > ul > li.dropdown .dropdown-menu li.dropdown-submenu.dropdown-reverse > .dropdown-menu {
		-webkit-transition: opacity .2s ease-out, -webkit-transform .2s ease-out;
		transition: opacity .2s ease-out, -webkit-transform .2s ease-out;
		transition: transform .2s ease-out, opacity .2s ease-out;
		transition: transform .2s ease-out, opacity .2s ease-out, -webkit-transform .2s ease-out;
		-webkit-transform: translate3d(20px, 0, 0);
		transform: translate3d(20px, 0, 0);
		left: auto;
		right: 100%;
		opacity: 0;
	}

	#header .header-nav-main-sub-effect-1 nav > ul > li.dropdown .dropdown-menu li.dropdown-submenu.dropdown-reverse:hover > .dropdown-menu {
		-webkit-transform: translate3d(0, 0, 0);
		transform: translate3d(0, 0, 0);
		opacity: 1;
	}
}
/* Header Nav Main Mobile */
@media (max-width: 991px) {
	#header .header-nav-main {
		position: absolute;
		background: transparent;
		width: 100%;
		top: 100%;
		left: 50%;
		-webkit-transform: translate3d(-50%, 0, 0);
		transform: translate3d(-50%, 0, 0);
	}

	#header .header-nav-main:before {
		content: '';
		display: block;
		position: absolute;
		top: 0;
		left: 50%;
		width: 100vw;
		height: 100%;
		background: #FFF;
		z-index: -1;
		-webkit-transform: translateX(-50%);
		transform: translateX(-50%);
	}

	#header .header-nav-main nav {
		max-height: 50vh;
		overflow: hidden;
		overflow-y: auto;
		padding: 0 15px;
	}

	#header .header-nav-main nav.collapsing {
		overflow-y: hidden;
	}

	#header .header-nav-main nav::-webkit-scrollbar {
		width: 5px;
	}

	#header .header-nav-main nav::-webkit-scrollbar-thumb {
		border-radius: 0px;
		background: rgba(204, 204, 204, 0.5);
	}

	#header .header-nav-main nav > ul {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		padding: 0.8rem 0;
	}

	#header .header-nav-main nav > ul li {
		border-bottom: 1px solid #e8e8e8;
		clear: both;
		display: block;
		float: none;
		margin: 0;
		padding: 0;
		position: relative;
	}

	#header .header-nav-main nav > ul li a {
		font-size: 13px;
		font-style: normal;
		line-height: 20px;
		padding: 7px 8px;
		margin: 1px 0;
		border-radius: 4px;
		text-align: left;
	}

	#header .header-nav-main nav > ul li a .fa-caret-down {
		line-height: 35px;
		min-height: 38px;
		min-width: 30px;
		position: absolute;
		right: 5px;
		text-align: center;
		top: 0;
	}

	#header .header-nav-main nav > ul li a.dropdown-toggle:after {
		content: none;
	}

	#header .header-nav-main nav > ul li a:active {
		background-color: #f8f9fa;
		color: inherit;
	}

	#header .header-nav-main nav > ul li.dropdown .dropdown-menu {
		background: transparent;
		padding: 0;
		margin: 0;
		font-size: 13px;
		-webkit-box-shadow: none;
		box-shadow: none;
		border-radius: 0;
		border: 0;
		clear: both;
		display: none;
		float: none;
		position: static;
	}

	#header .header-nav-main nav > ul li.dropdown .dropdown-menu li.dropdown-submenu.opened > .dropdown-menu {
		display: block;
		margin-left: 20px;
	}

	#header .header-nav-main nav > ul li.dropdown.opened > .dropdown-menu {
		display: block;
		margin-left: 20px;
	}

	#header .header-nav-main nav > ul li.dropdown-mega .dropdown-mega-sub-title {
		margin-top: 10px;
		display: block;
		text-align: left;
	}

	#header .header-nav-main nav > ul li.dropdown-mega .dropdown-mega-sub-nav {
		margin: 0 0 0 20px;
		padding: 0;
		list-style: none;
	}

	#header .header-nav-main nav > ul li.dropdown-mega .dropdown-mega-sub-nav > li > a {
		display: block;
		text-decoration: none;
	}

	#header .header-nav-main nav > ul li:last-child {
		border-bottom: 0;
	}

	#header .header-nav-main nav > ul > li > a {
		text-transform: uppercase;
		font-weight: 700;
		margin-top: 1px;
		margin-bottom: 1px;
		color: #CCC;
	}

	#header .header-nav-main nav > ul > li > a:active {
		color: #CCC;
	}

	#header .header-nav-main nav > ul > li > a.active {
		color: #FFF;
		background: #CCC;
	}

	#header .header-nav-main nav > ul > li > a.active:focus, #header .header-nav-main nav > ul > li > a.active:hover {
		color: #FFF;
		background: #CCC;
	}

	#header .header-nav-main nav .not-included {
		margin: 0;
	}

	#header .header-nav-main a > .thumb-info-preview {
		display: none !important;
	}

	#header .header-btn-collapse-nav {
		outline: 0;
		display: block;
		position: relative;
		z-index: 1;
	}

	#header.header-transparent .header-nav-main:before, #header.header-semi-transparent .header-nav-main:before, #header.header-semi-transparent-light .header-nav-main:before {
		width: calc(100% - 30px);
		border-radius: 4px;
	}

	#header.header-transparent .header-nav-main nav, #header.header-semi-transparent .header-nav-main nav, #header.header-semi-transparent-light .header-nav-main nav {
		padding: 0 30px;
	}

	#header.header-transparent .header-nav-main nav::-webkit-scrollbar-thumb, #header.header-semi-transparent .header-nav-main nav::-webkit-scrollbar-thumb, #header.header-semi-transparent-light .header-nav-main nav::-webkit-scrollbar-thumb {
		border-color: transparent;
	}

	#header.header-transparent .header-body:before {
		border-bottom: none !important;
	}

	#header.header-semi-transparent-light .header-body:before {
		border-bottom: none !important;
	}

	#header .header-nav.header-nav-stripe {
		min-height: 90px;
	}

	#header .header-nav.header-nav-top-line {
		min-height: 70px;
	}

	#header .header-nav.header-nav-dark-dropdown {
		min-height: 70px;
	}
}

@media (min-width: 992px) {
	html:not(.sticky-header-active) #header.header-transparent .header-nav-main:not(.header-nav-main-dark) nav > ul > li > a {
		color: #FFF;
	}

	html:not(.sticky-header-active) #header.header-transparent .header-nav-main:not(.header-nav-main-dark) nav > ul > li > a.dropdown-toggle:after {
		border-color: #FFF transparent transparent transparent;
	}

	html:not(.sticky-header-active) #header.header-transparent .header-nav-main:not(.header-nav-main-dark) nav > ul > li > a.active {
		color: #CCC;
	}

	html:not(.sticky-header-active) #header.header-transparent .header-nav-main:not(.header-nav-main-dark) nav > ul > li > a.active.dropdown-toggle:after {
		border-color: #CCC transparent transparent transparent;
	}

	html:not(.sticky-header-active) #header.header-transparent .header-nav-main:not(.header-nav-main-dark) nav > ul > li.open > a.active, html:not(.sticky-header-active) #header.header-transparent .header-nav-main:not(.header-nav-main-dark) nav > ul > li:hover > a.active {
		color: #FFF;
	}

	html:not(.sticky-header-active) #header.header-transparent .header-nav-main:not(.header-nav-main-dark) nav > ul > li.open > a.active.dropdown-toggle:after, html:not(.sticky-header-active) #header.header-transparent .header-nav-main:not(.header-nav-main-dark) nav > ul > li:hover > a.active.dropdown-toggle:after {
		border-color: #FFF transparent transparent transparent;
	}

	html #header.header-semi-transparent .header-nav-main nav > ul > li > a {
		color: #FFF;
	}

	html #header.header-semi-transparent .header-nav-main nav > ul > li > a.dropdown-toggle:after {
		border-color: #FFF transparent transparent transparent;
	}

	html #header.header-semi-transparent .header-nav-main nav > ul > li > a.active {
		color: #CCC;
	}

	html #header.header-semi-transparent .header-nav-main nav > ul > li > a.active.dropdown-toggle:after {
		border-color: #CCC transparent transparent transparent;
	}

	html #header.header-semi-transparent .header-nav-main nav > ul > li.open > a.active, html #header.header-semi-transparent .header-nav-main nav > ul > li:hover > a.active {
		color: #FFF;
	}

	html #header.header-semi-transparent .header-nav-main nav > ul > li.open > a.active.dropdown-toggle:after, html #header.header-semi-transparent .header-nav-main nav > ul > li:hover > a.active.dropdown-toggle:after {
		border-color: #FFF transparent transparent transparent;
	}

	html:not(.sticky-header-active) #header.header-semi-transparent-light .header-nav-main nav > ul > li > a {
		color: #FFF;
	}

	html:not(.sticky-header-active) #header.header-semi-transparent-light .header-nav-main nav > ul > li > a.dropdown-toggle:after {
		border-color: #FFF transparent transparent transparent;
	}

	html:not(.sticky-header-active) #header.header-semi-transparent-light .header-nav-main nav > ul > li > a.active {
		color: #CCC;
	}

	html:not(.sticky-header-active) #header.header-semi-transparent-light .header-nav-main nav > ul > li > a.active.dropdown-toggle:after {
		border-color: #CCC transparent transparent transparent;
	}

	html:not(.sticky-header-active) #header.header-semi-transparent-light .header-nav-main nav > ul > li.open > a.active, html:not(.sticky-header-active) #header.header-semi-transparent-light .header-nav-main nav > ul > li:hover > a.active {
		color: #FFF;
	}

	html:not(.sticky-header-active) #header.header-semi-transparent-light .header-nav-main nav > ul > li.open > a.active.dropdown-toggle:after, html:not(.sticky-header-active) #header.header-semi-transparent-light .header-nav-main nav > ul > li:hover > a.active.dropdown-toggle:after {
		border-color: #FFF transparent transparent transparent;
	}
}
/* Responsive */
@media (min-width: 992px) {
	#header .header-body {
		height: auto !important;
	}
}

@media (max-width: 991px) {
	#header .header-logo img {
		z-index: 1;
	}

	#header .header-nav {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
	}

	#header.header-narrow .header-nav.header-nav-stripe {
		min-height: 70px;
	}
}
/* Mega Menu (Signin/SignUp) */
#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-signin label {
	font-size: 14.4px;
	font-size: 0.9rem;
	color: #777;
}

#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-signin .dropdown-menu {
	width: 40%;
	right: 15px;
	left: auto;
	border-radius: 6px 0 6px 6px;
}

#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-signin .dropdown-menu, #header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-signin .dropdown-menu a {
	padding: 0;
	-webkit-transform: translate3d(0, 2px, 0);
	transform: translate3d(0, 2px, 0);
}

#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-signin .fa-user {
	margin-right: 5px;
}

#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-signin .dropdown-mega-sub-title {
	margin-bottom: 10px;
	display: block;
}

#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-signin .sign-up-info, #header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-signin .log-in-info {
	text-align: center;
	display: block;
	font-size: 14.4px;
	font-size: 0.9rem;
}

#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-signin.signin .signin-form {
	display: block;
}

#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-signin.signin .signup-form, #header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-signin.signin .recover-form {
	display: none;
}

#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-signin.signup .signin-form {
	display: none;
}

#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-signin.signup .signup-form {
	display: block;
}

#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-signin.signup .recover-form {
	display: none;
}

#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-signin.recover .signin-form, #header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-signin.recover .signup-form {
	display: none;
}

#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-signin.recover .recover-form {
	display: block;
}

#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-signin.recover .recover-form p {
	font-size: 14.4px;
	font-size: 0.9rem;
}

#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-signin.logged .user-avatar .img-thumbnail {
	float: left;
	margin-right: 15px;
}

#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-signin.logged .user-avatar .img-thumbnail img {
	max-width: 55px;
}

#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-signin.logged .user-avatar strong {
	display: block;
	padding-top: 7px;
}

#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-signin.logged .user-avatar span {
	display: block;
	font-size: 12px;
}

#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-signin.logged .user-avatar p {
	margin: 0 0 0 25px;
	text-align: left;
}

#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-signin.logged .list-account-options {
	list-style: none;
	margin: 6px 0 0;
	padding: 0;
}

#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-signin.logged .list-account-options a:hover {
	text-decoration: none;
}

/* Responsive */
@media (max-width: 991px) {
	#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-signin {
		margin-right: 0;
	}

	#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-signin .dropdown-toggle {
		display: none;
	}

	#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-signin .dropdown-menu {
		display: block !important;
		margin: 0 !important;
		padding: 0 10px 0 10px;
		width: 100%;
		right: 0;
	}

	#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-signin .dropdown-menu .dropdown-mega-sub-title {
		font-size: 14px;
		font-weight: 600;
		text-transform: uppercase;
		padding-top: 5px;
		padding-bottom: 0;
	}

	#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-signin.logged .dropdown-toggle {
		display: none;
	}

	#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-signin.logged .dropdown-mega-content {
		margin: 15px 0 !important;
	}

	#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-signin.logged .list-account-options {
		margin: 15px 0 0 0 !important;
	}

	#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-signin.logged .list-account-options > li > a {
		line-height: 35px;
	}
}
/* Mega Menu (Signin/SignUp) */
#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-shop .dropdown-menu {
	width: 40%;
	right: 15px;
	left: auto;
	border-radius: 6px 0 6px 6px;
}

#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-shop .dropdown-menu li table {
	width: 100%;
	margin-top: 0;
}

#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-shop .dropdown-menu li table tr td a {
	background-color: transparent !important;
	color: #333;
	padding: 0;
	margin: 0;
	display: block;
}

#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-shop .dropdown-menu li table tr td a.remove {
	float: right;
}

#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-shop .dropdown-menu li table tr td a:hover {
	text-decoration: none;
}

#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-shop .fa-shopping-cart {
	margin-right: 5px;
}

#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-shop .product-thumbnail {
	width: 120px;
}

#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-shop .product-actions {
	text-align: right;
	width: 80px;
}

#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-shop .actions-continue {
	margin-top: 10px;
	padding-top: 20px;
	border-top: 1px solid #DDD;
	clear: both;
}

#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-shop .actions-continue .btn {
	margin-bottom: 10px;
}

/* Responsive */
@media (max-width: 991px) {
	#header .header-nav-main nav > ul > li.dropdown-mega.dropdown-mega-shop .dropdown-menu {
		margin: 15px 0;
		padding: 0 15px;
		width: 100%;
	}
}

@media (min-width: 992px) {
	html.side-header .main .container, html.side-header #footer .container {
		padding: 0 35px;
		width: auto !important;
		max-width: 1210px;
	}

	html.side-header body > .body {
		margin: 0 0 0 255px;
		width: auto;
		overflow-x: hidden;
		overflow-y: visible;
	}

	html.side-header body > .body .forcefullwidth_wrapper_tp_banner .rev_slider_wrapper {
		width: 100% !important;
		left: auto !important;
	}

	html.side-header #header {
		background: #FFF;
		position: fixed;
		-webkit-box-shadow: 0 0 18px rgba(0, 0, 0, 0.07);
		box-shadow: 0 0 18px rgba(0, 0, 0, 0.07);
		top: 0;
		left: 0;
		width: 255px;
		height: 100%;
	}

	html.side-header #header .header-body {
		border-top: 0;
	}

	html.side-header #header .header-container {
		width: 100%;
	}

	html.side-header #header .header-row {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	html.side-header #header .header-search {
		width: 100%;
		margin: 0.7rem 0;
	}

	html.side-header #header .header-nav-top .nav > li > a, html.side-header #header .header-nav-top .nav > li > span {
		display: block;
	}

	html.side-header #header .header-logo {
		margin: 2rem 0;
	}

	html.side-header #header .header-nav {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	html.side-header #header .header-nav-main,
	html.side-header #header .header-nav-main nav,
	html.side-header #header .header-nav-main .nav {
		width: 100%;
	}

	html.side-header #header .nav {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
		width: 100%;
	}

	html.side-header #header .header-nav-main nav > ul > li.dropdown > a.dropdown-toggle:after {
		content: "";
		border-color: transparent transparent transparent #CCC;
		position: absolute;
		right: 15px;
		top: 50%;
		border-top: .3em solid;
		border-bottom: .3em solid transparent;
		border-left: .3em solid transparent;
		border-right: 0;
		-webkit-transform: translate3d(0, -50%, 0);
		transform: translate3d(0, -50%, 0);
	}

	html.side-header #header .header-nav-main nav > ul > li.dropdown.open > .dropdown-menu, html.side-header #header .header-nav-main nav > ul > li.dropdown:hover > .dropdown-menu {
		top: 3px;
		left: 100%;
		border-top: 0;
		border-left: 5px solid #CCC;
		margin-left: -5px;
	}

	html.side-header #header .header-nav-main nav > ul > li.dropdown li.dropdown-submenu:hover > .dropdown-menu {
		margin-top: -5px;
		border-top: 0;
	}

	html.side-header #header .header-nav-main nav > ul > li.dropdown.open > a, html.side-header #header .header-nav-main nav > ul > li.dropdown:hover > a {
		padding-bottom: 0.5rem;
	}

	html.side-header #header .header-nav-main nav > ul > li.dropdown.open > a:before, html.side-header #header .header-nav-main nav > ul > li.dropdown:hover > a:before {
		content: none;
	}

	html.side-header #header .header-nav-main nav > ul > li.dropdown-mega {
		position: relative;
	}

	html.side-header #header .header-nav-main nav > ul > li.dropdown-mega .dropdown-menu {
		min-width: 720px;
	}

	html.side-header #header .header-nav-main nav > ul > li {
		margin-top: 1px;
	}

	html.side-header #header .header-nav-main nav > ul > li > a {
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
	}

	html.side-header #header .header-nav-main nav > ul > li .dropdown-menu.dropdown-reverse li a {
		padding-right: 8px;
		padding-left: 20px;
	}

	html.side-header #header .header-nav-main nav > ul > li .dropdown-menu.dropdown-reverse li.dropdown-submenu > a:after {
		border-width: 4px 4px 4px 0;
	}

	html.side-header #header .header-social-icons {
		margin: 2rem 0 !important;
	}

	html.side-header-right body > .body {
		margin: 0 255px 0 0;
	}

	html.side-header-right #header {
		left: auto;
		right: 0;
	}

	html.side-header-right #header .header-nav-main nav > ul > li.dropdown > a.dropdown-toggle {
		padding-right: 0px;
		padding-left: 30px;
	}

	html.side-header-right #header .header-nav-main nav > ul > li.dropdown > a.dropdown-toggle:after {
		left: 15px;
		right: auto;
		border-right: .3em solid transparent;
		border-left: 0;
	}

	html.side-header-right #header .header-nav-main nav > ul > li.dropdown.open > .dropdown-menu, html.side-header-right #header .header-nav-main nav > ul > li.dropdown:hover > .dropdown-menu {
		right: 100%;
		left: auto;
		border-right: 5px solid #CCC;
		border-left: 0;
		margin-left: 0;
		margin-right: -5px;
		border-radius: 4px 0 0 4px;
	}

	html.side-header-right #header .header-nav-main nav > ul > li.dropdown .dropdown-menu li.dropdown-submenu > a {
		padding-left: 25px;
	}

	html.side-header-right #header .header-nav-main nav > ul > li.dropdown .dropdown-menu li.dropdown-submenu > a:after {
		left: 10px;
		right: initial;
	}

	html.ie.side-header-right #header .header-nav-main nav > ul > li.dropdown .dropdown-menu li.dropdown-submenu > a:after {
		left: 10px;
		right: initial;
		width: 0;
	}

	html.side-header-semi-transparent body > .body {
		margin: 0;
	}

	html.side-header-semi-transparent #header {
		background: rgba(0, 0, 0, 0.3);
	}

	html.side-header-semi-transparent #header .header-body {
		background: transparent !important;
	}
}

@media (max-height: 768px) {
	html.side-header #header .header-nav-main nav > ul > li.dropdown.open > .dropdown-menu, html.side-header #header .header-nav-main nav > ul > li.dropdown:hover > .dropdown-menu {
		bottom: 0;
		top: auto;
	}
}

html[dir="rtl"] #header .header-search .btn-light {
	right: -35px;
}

html.safari #header.header-transparent .header-body, html.safari #header.header-semi-transparent .header-body, html.safari #header.header-semi-transparent-light .header-body {
	overflow: visible !important;
}

html.safari.sticky-header-active #header .header-body {
	overflow: visible !important;
}

html.ie #header .header-column .header-extra-info .feature-box .feature-box-info {
	-webkit-box-flex: 0;
	-ms-flex: none;
	flex: none;
}

/* Page Header */
.page-header {
	background-color: #171717;
	border-bottom: 5px solid #CCC;
	border-top: 5px solid #384045;
	margin: 0 0 35px 0;
	min-height: 50px;
	padding: 20px 0;
	position: relative;
	text-align: left;
}

.page-header .breadcrumb {
	background: none;
	margin: 0;
	padding: 0;
	position: relative;
	z-index: 1;
}

.page-header .breadcrumb > li {
	display: inline-block;
	font-size: 0.85em;
	text-transform: uppercase;
	text-shadow: none;
}

.page-header .breadcrumb > li + li:before {
	color: inherit;
	opacity: 0.5;
	font-family: 'Font Awesome 5 Free';
	font-weight: 900;
	content: "\f105";
	padding: 0 7px 0 5px;
}

.page-header h1 {
	border-bottom: 5px solid #CCC;
	color: #fff;
	display: inline-block;
	font-size: 32px;
	font-weight: 200;
	line-height: 32px;
	margin: 0 0 -25px;
	padding: 0 0 25px;
	position: relative;
}

.page-header h1 span {
	color: #999da3;
	display: block;
	font-size: 0.6em;
	font-weight: 300;
	line-height: 1.3em;
	margin: 7px 0 0;
}

.page-header-no-title-border h1 {
	border: 0;
}

/* Page Header - Color */
.page-header-color {
	background-color: #F7F7F7;
	border-bottom: 5px solid #F4F4F4;
	border-top: none;
}

.page-header-color h1 {
	border-bottom: none;
	color: #1d2127;
	font-size: 2.3em;
	padding: 0;
	margin: 0;
}

.page-header-color h1 span {
	opacity: 0.65;
}

.page-header-color .breadcrumb {
	text-align: right;
	float: right;
}

@media (max-width: 991px) {
	.page-header-color .breadcrumb {
		padding: 18px 0px 0;
		text-align: left;
		float: none;
	}
}

.page-header-color .breadcrumb > .active {
	opacity: 0.65;
}

/* Page Header - More Padding */
.page-header-more-padding {
	padding: 35px 0;
}

/* Page Header - Light */
.page-header-light {
	background-color: #F7F7F7;
	border-bottom: 5px solid #F4F4F4;
	border-top: none;
}

.page-header-light h1 {
	border-bottom: none;
	color: #1d2127;
	font-size: 2.3em;
}

.page-header-light .breadcrumb {
	text-align: right;
	float: right;
}

@media (max-width: 991px) {
	.page-header-light .breadcrumb {
		padding: 18px 0px 0;
		text-align: left;
		float: none;
	}
}

/* Page Header - Reverse */
.page-header-reverse {
	text-align: right;
}

.page-header-reverse .breadcrumb {
	text-align: left;
}

@media (max-width: 991px) {
	.page-header-reverse .breadcrumb {
		text-align: right;
	}
}

/* Page Header - Parallax */
.page-header.parallax {
	margin: 0 0 35px;
	padding: 180px 0 60px;
}

.page-header-center {
	text-align: center;
}

.page-header-center .breadcrumb {
	text-align: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	margin: 15px 0 0;
}

/* Page Header Custom Background */
.page-header-custom-background {
	padding: 45px 0;
	background-repeat: repeat;
	background-color: transparent;
	border-top: 0;
	border-bottom: 0;
}

.page-header-custom-background h1 {
	color: #FFF;
	border: 0;
}

.page-header-custom-background h1 span {
	color: #FFF;
	opacity: 0.7;
}

.page-header-custom-background .breadcrumb {
	text-align: right;
	float: right;
}

@media (max-width: 991px) {
	.page-header-custom-background .breadcrumb {
		padding: 18px 0px 0;
		text-align: left;
		float: none;
	}
}

.page-header-custom-background .breadcrumb li.active {
	color: #FFF;
	opacity: 0.7;
}

.page-header-custom-background .breadcrumb a {
	color: #FFF;
}

/* Page Header Custom - Create your Own */
.page-header.custom-product {
	background-image: url(../img/custom-header-bg.jpg);
	background-repeat: repeat;
	background-color: #999;
	border-top: 5px solid #888;
	padding: 0;
	overflow: hidden;
}

.page-header.custom-product .row {
	position: relative;
}

.page-header.custom-product p.lead {
	color: #E8E8E8;
}

.page-header.custom-product h1 {
	color: #FFF;
	margin-bottom: 10px;
	margin-top: 30px;
	border: 0;
}

/* Responsive */
@media (min-width: 992px) {
	.page-header-more-padding .breadcrumb-valign-mid,
	.page-header-custom-background .breadcrumb-valign-mid,
	.page-header-float-breadcrumb .breadcrumb-valign-mid {
		text-align: right;
	}

	.page-header-more-padding.page-header-reverse .breadcrumb-valign-mid,
	.page-header-custom-background.page-header-reverse .breadcrumb-valign-mid,
	.page-header-float-breadcrumb.page-header-reverse .breadcrumb-valign-mid {
		text-align: left;
	}
}

@media (max-width: 991px) {
	.page-header .breadcrumb {
		float: none !important;
		position: static !important;
	}

	.page-header .breadcrumb-valign-mid {
		margin-top: 15px;
	}

	.page-header-reverse .breadcrumb-valign-mid {
		margin-top: 0;
		margin-bottom: 15px;
	}
}

@media (max-width: 575px) {
	.page-header h1 {
		font-size: 26px;
		line-height: 26px;
	}
}
/* Parallax */
.parallax {
	background-color: transparent;
	background-position: 50% 50%;
	background-repeat: repeat;
	background-attachment: fixed;
	margin: 70px 0;
	padding: 50px 0;
	position: relative;
	clear: both;
}

.parallax blockquote {
	border: 0;
	padding: 0 10%;
}

.parallax blockquote i.fa-quote-left {
	font-size: 34px;
	position: relative;
	left: -5px;
}

.parallax blockquote p {
	font-size: 24px;
	line-height: 30px;
}

.parallax blockquote span {
	font-size: 16px;
	line-height: 20px;
	color: #999;
	position: relative;
	left: -5px;
}

.parallax.parallax-disabled {
	background-attachment: scroll !important;
	background-position: 50% 50% !important;
}

/* Section Featured */
section.section {
	background: #f4f4f4;
	border-top: 5px solid #f1f1f1;
	margin: 30px 0;
	padding: 50px 0;
}

section.section.section-height-1 {
	padding: 12.307px 0;
	padding: 0.7692307692rem 0;
}

section.section.section-height-2 {
	padding: 36.923px 0;
	padding: 2.3076923077rem 0;
}

section.section.section-height-3 {
	padding: 73.846px 0;
	padding: 4.6153846154rem 0;
}

section.section.section-height-4 {
	padding: 110.769px 0;
	padding: 6.9230769231rem 0;
}

section.section.section-height-5 {
	padding: 147.692px 0;
	padding: 9.2307692308rem 0;
}

section.section.section-no-background {
	background: transparent;
	border-top-color: transparent;
}

section.section.section-center {
	text-align: center;
}

section.section.section-text-light h1, section.section.section-text-light h2, section.section.section-text-light h3, section.section.section-text-light h4, section.section.section-text-light h5, section.section.section-text-light h6 {
	color: #FFF;
}

section.section.section-text-light p {
	color: #e6e6e6;
}

section.section.section-background {
	background-repeat: repeat;
	border: 0;
}

section.section.section-overlay {
	position: relative;
}

section.section.section-overlay:before {
	content: "";
	display: block;
	background: url("../img/video-overlay.png") repeat scroll 0 0 transparent;
	bottom: 0;
	height: 100%;
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
	width: 100%;
}

section.section.section-overlay-opacity {
	position: relative;
}

section.section.section-overlay-opacity:before {
	content: "";
	display: block;
	background: rgba(0, 0, 0, 0.5);
	bottom: 0;
	height: 100%;
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
	width: 100%;
}

section.section.section-overlay-opacity.section-overlay-opacity-scale-1:before {
	background: rgba(0, 0, 0, 0.1);
}

section.section.section-overlay-opacity.section-overlay-opacity-scale-2:before {
	background: rgba(0, 0, 0, 0.2);
}

section.section.section-overlay-opacity.section-overlay-opacity-scale-3:before {
	background: rgba(0, 0, 0, 0.3);
}

section.section.section-overlay-opacity.section-overlay-opacity-scale-4:before {
	background: rgba(0, 0, 0, 0.4);
}

section.section.section-overlay-opacity.section-overlay-opacity-scale-5:before {
	background: rgba(0, 0, 0, 0.5);
}

section.section.section-overlay-opacity.section-overlay-opacity-scale-6:before {
	background: rgba(0, 0, 0, 0.6);
}

section.section.section-overlay-opacity.section-overlay-opacity-scale-7:before {
	background: rgba(0, 0, 0, 0.7);
}

section.section.section-overlay-opacity.section-overlay-opacity-scale-8:before {
	background: rgba(0, 0, 0, 0.8);
}

section.section.section-overlay-opacity.section-overlay-opacity-scale-9:before {
	background: rgba(0, 0, 0, 0.9);
}

section.section.section-overlay-opacity-light:before {
	background: rgba(255, 255, 255, 0.5);
}

section.section.section-overlay-opacity-light.section-overlay-opacity-light-scale-1:before {
	background: rgba(255, 255, 255, 0.1);
}

section.section.section-overlay-opacity-light.section-overlay-opacity-light-scale-2:before {
	background: rgba(255, 255, 255, 0.2);
}

section.section.section-overlay-opacity-light.section-overlay-opacity-light-scale-3:before {
	background: rgba(255, 255, 255, 0.3);
}

section.section.section-overlay-opacity-light.section-overlay-opacity-light-scale-4:before {
	background: rgba(255, 255, 255, 0.4);
}

section.section.section-overlay-opacity-light.section-overlay-opacity-light-scale-5:before {
	background: rgba(255, 255, 255, 0.5);
}

section.section.section-overlay-opacity-light.section-overlay-opacity-light-scale-6:before {
	background: rgba(255, 255, 255, 0.6);
}

section.section.section-overlay-opacity-light.section-overlay-opacity-light-scale-7:before {
	background: rgba(255, 255, 255, 0.7);
}

section.section.section-overlay-opacity-light.section-overlay-opacity-light-scale-8:before {
	background: rgba(255, 255, 255, 0.8);
}

section.section.section-overlay-opacity-light.section-overlay-opacity-light-scale-9:before {
	background: rgba(255, 255, 255, 0.9);
}

section.section.section-default-scale-lighten {
	background-color: #fcfcfc !important;
	border-top-color: #fcfcfc !important;
}

section.section.section-video {
	background: none !important;
	border: 0;
}

section.section.section-parallax {
	background-color: transparent;
	background-position: 50% 50%;
	background-repeat: repeat;
	background-attachment: fixed;
	border: 0;
}

section.section.section-parallax.parallax-disabled {
	background-attachment: scroll !important;
	background-position: 50% 50% !important;
}

section.section.section-no-border {
	border: 0;
}

section.section.section-with-divider {
	margin: 56px 0 35px;
}

section.section.section-with-divider .divider {
	margin: -56px 0 44px;
}

section.section.section-footer {
	margin-bottom: -50px;
}

section.section.section-with-mockup {
	margin-top: 120px;
}

section.section.section-front {
	position: relative;
	z-index: 1;
}

/* Responsive */
@media (max-width: 991px) {
	.container-fluid > .row > .p-0 section.section {
		margin-bottom: 0;
		margin-top: 0;
	}

	.container-fluid > .row > .p-0:first-child section.section {
		margin-bottom: 0;
		margin-top: 30px;
	}

	.container-fluid > .row > .p-0:last-child section.section {
		margin-bottom: 30px;
		margin-top: 0;
	}
}
/* Sidebar */
aside ul.nav-list > li > a {
	color: #666;
	font-size: 0.9em;
	padding: 8px 0 8px 18px;
}

/* Home Slider - Revolution Slider */
.slider-container {
	background: #171717;
	height: 500px;
	overflow: hidden;
	width: 100%;
	direction: ltr;
}

.slider-container .tparrows {
	border-radius: 6px;
}

.slider-container .slider-single-slide .tparrows {
	display: none;
}

.slider-container .top-label {
	color: #FFF;
	font-size: 24px;
	font-weight: 300;
}

.slider-container .main-label {
	color: #FFF;
	font-size: 62px;
	line-height: 62px;
	font-weight: 800;
	text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);
}

.slider-container .featured-label {
	color: #FFF;
	font-size: 52px;
	line-height: 52px;
	font-weight: 800;
	text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.15);
}

.slider-container .bottom-label {
	color: #FFF;
	font-size: 20px;
	line-height: 20px;
	font-weight: 300;
}

.slider-container .blackboard-text {
	color: #BAB9BE;
	font-size: 46px;
	line-height: 46px;
	font-family: "Shadows Into Light", cursive;
	text-transform: uppercase;
}

.slider-container .btn.btn-lg.btn-slider-action {
	font-size: 22px;
	font-weight: 600;
	line-height: 20px;
	padding: 19.2px 28.8px;
	padding: 1.2rem 1.8rem;
}

.slider-container .top-label-dark, .slider-container .main-label-dark, .slider-container .bottom-label-dark {
	color: #333;
}

.slider-container .tp-caption-dark a {
	color: #333;
}

.slider-container .tp-caption-dark a:hover {
	color: #333;
}

.slider-container .tp-caption-overlay {
	background: #000;
	padding: 10px;
}

.slider-container .tp-caption-overlay-opacity {
	background: rgba(0, 0, 0, 0.4);
	padding: 10px;
}

.slider-container .tp-opacity-overlay {
	background: rgba(0, 0, 0, 0.75);
}

.slider-container.transparent {
	background-color: transparent;
}

.slider-container.light {
	background-color: #EAEAEA;
}

.slider-container.light .tp-bannershadow {
	opacity: 0.05;
	top: 0;
	bottom: auto;
	z-index: 1000;
}

.slider-container.light .featured-label, .slider-container.light .top-label, .slider-container.light .main-label, .slider-container.light .bottom-label {
	color: #333;
}

.slider-container.light .tp-caption a {
	color: #333;
}

.slider-container.light .tp-caption a:hover {
	color: #333;
}

/* Arrows */
.tparrows.tparrows-carousel {
	display: inline-block;
	position: absolute;
	width: 30px;
	height: auto;
	padding: 20px 0;
}

.tparrows.tparrows-carousel.tp-leftarrow {
	right: auto !important;
	left: -30px !important;
	border-radius: 0 4px 4px 0;
}

.tparrows.tparrows-carousel.tp-leftarrow:before {
	font-family: 'Font Awesome 5 Free';
	font-weight: 900;
	content: "\f053";
	position: relative;
	left: -1px;
	top: 1px;
}

.tparrows.tparrows-carousel.tp-rightarrow {
	right: -60px !important;
	left: auto !important;
	border-radius: 4px 0 0 4px;
}

.tparrows.tparrows-carousel.tp-rightarrow:before {
	font-family: 'Font Awesome 5 Free';
	font-weight: 900;
	content: "\f054";
	position: relative;
	left: 2px;
	top: 1px;
}

/* Slider IE Fix */
html.ie .forcefullwidth_wrapper_tp_banner {
	overflow: hidden;
}

/* Embed Border */
.rev_slider embed,
.rev_slider iframe,
.rev_slider object,
.rev_slider video {
	border: 0 !important;
}

/* Defaults */
.hesperiden-custom {
	padding: 10px 0 !important;
}

.hesperiden-custom .tp-tab {
	border: 0 !important;
}

/* Slider With Overlay */
.slider-with-overlay {
	position: relative;
}

.slider-with-overlay .slider-container {
	z-index: auto;
}

/* Slider Contact Form */
.slider-contact-form {
	margin: -45px 0 25px;
}

.slider-contact-form .featured-box {
	text-align: left;
}

.slider-contact-form label.error {
	display: none !important;
}

.slider-contact-form .alert {
	padding: 6px;
	text-align: center;
}

.slider-contact-form textarea {
	resize: none;
}

/* Responsive */
@media (min-width: 992px) {
	.slider-contact-form {
		margin: 0;
		position: absolute;
		top: 20px;
		z-index: 99;
		width: 100%;
	}
}
/* Boxed */
html.boxed .slider-container {
	width: 100% !important;
	left: auto !important;
}

/* Home Slider - Nivo Slider */
.nivo-slider {
	position: relative;
}

.nivo-slider .theme-default .nivoSlider {
	border-radius: 10px 10px 0 0;
	-webkit-box-shadow: none;
	box-shadow: none;
	margin-top: 10px;
}

.nivo-slider .theme-default .nivoSlider img {
	border-radius: 10px 10px 0 0;
}

.nivo-slider .theme-default .nivo-controlNav {
	margin-top: -72px;
	position: absolute;
	z-index: 99;
	right: 5px;
	bottom: -17px;
}

.nivo-slider .theme-default .nivo-caption {
	padding-left: 20px;
}

/* Custom Map */
section.section-custom-map {
	background: transparent url(../img/map.png) center 0 no-repeat;
	padding: 129px 0 0;
	margin: 20px 0 0;
	border: 0;
}

section.section-custom-map section.section {
	border-top-color: rgba(241, 241, 241, 0.8);
	background: rgba(244, 244, 244, 0.8);
}

section.section-custom-map-2 {
	background: transparent url(../img/map-2.png) center 0 no-repeat;
	padding: 129px 0 0;
	margin: 50px 0 0;
	border: 0;
}

section.section-custom-map-2 section.section {
	border-top-color: rgba(241, 241, 241, 0.8);
	background: rgba(244, 244, 244, 0.8);
}

/* Home Intro */
.home-intro {
	background-color: #171717;
	margin-bottom: 60px;
	overflow: hidden;
	padding: 35.2px 0;
	padding: 2.2rem 0;
	position: relative;
	text-align: left;
}

.home-intro p {
	color: #FFF;
	display: inline-block;
	font-size: 1.4em;
	font-weight: 300;
	margin-bottom: 0;
}

.home-intro p span {
	color: #999;
	display: block;
	font-size: 0.8em;
	padding-top: 5px;
}

.home-intro p em {
	font-family: "Shadows Into Light", cursive;
	font-size: 1.6em;
}

.home-intro .get-started {
	position: relative;
}

.home-intro .get-started .btn {
	position: relative;
	z-index: 1;
}

.home-intro .get-started .btn i {
	font-size: 1.1em;
	font-weight: normal;
	margin-left: 5px;
}

.home-intro .get-started a {
	color: #FFF;
}

.home-intro .get-started a:not(.btn) {
	color: #777;
}

.home-intro .learn-more {
	margin-left: 15px;
}

.home-intro.light {
	background-color: #E2E2E2;
}

.home-intro.light.secondary {
	background-color: #E0E1E4;
}

.home-intro.light p {
	color: #777;
}

/* Home Intro Compact */
.home-intro-compact {
	border-radius: 0 0 10px 10px;
	margin-top: -10px;
}

.home-intro-compact p {
	padding-left: 20px;
}

.home-intro-compact .get-started {
	padding-right: 20px;
}

/* Responsive */
@media (max-width: 991px) {
	/* Home Intro */
	.home-intro p {
		margin-bottom: 2rem;
	}

	.home-intro-compact .get-started {
		padding-left: 20px;
	}
}

@media (max-width: 767px) {
	/* Home Intro */
	.home-intro-compact {
		margin-left: 0;
		margin-right: 0;
		padding-left: 0;
		padding-right: 0;
	}
}
/* Home Concept SVG Animations */
@-webkit-keyframes animatedLineBg {
	from {
		stroke-dasharray: 1000;
	}

	to {
		stroke-dasharray: 1360;
	}
}

@keyframes animatedLineBg {
	from {
		stroke-dasharray: 1000;
	}

	to {
		stroke-dasharray: 1360;
	}
}

@-webkit-keyframes animatedLineSmallCircle {
	from {
		stroke-dasharray: 1000;
	}

	to {
		stroke-dasharray: 1120;
	}
}

@keyframes animatedLineSmallCircle {
	from {
		stroke-dasharray: 1000;
	}

	to {
		stroke-dasharray: 1120;
	}
}

@-webkit-keyframes animatedLineBigCircle {
	from {
		stroke-dasharray: 1000;
	}

	to {
		stroke-dasharray: 1266;
	}
}

@keyframes animatedLineBigCircle {
	from {
		stroke-dasharray: 1000;
	}

	to {
		stroke-dasharray: 1266;
	}
}

@-webkit-keyframes animatedInitialPin {
	0% {
		-webkit-transform: translate(2px, 69px) scale(0.23);
		transform: translate(2px, 69px) scale(0.23);
	}

	33% {
		-webkit-transform: translate(2px, 79px) scale(0.23);
		transform: translate(2px, 79px) scale(0.23);
	}

	66% {
		-webkit-transform: translate(2px, 74px) scale(0.23);
		transform: translate(2px, 74px) scale(0.23);
	}

	100% {
		-webkit-transform: translate(2px, 79px) scale(0.23);
		transform: translate(2px, 79px) scale(0.23);
	}
}

@keyframes animatedInitialPin {
	0% {
		-webkit-transform: translate(2px, 69px) scale(0.23);
		transform: translate(2px, 69px) scale(0.23);
	}

	33% {
		-webkit-transform: translate(2px, 79px) scale(0.23);
		transform: translate(2px, 79px) scale(0.23);
	}

	66% {
		-webkit-transform: translate(2px, 74px) scale(0.23);
		transform: translate(2px, 74px) scale(0.23);
	}

	100% {
		-webkit-transform: translate(2px, 79px) scale(0.23);
		transform: translate(2px, 79px) scale(0.23);
	}
}

@-webkit-keyframes animatedFinalPin {
	0% {
		-webkit-transform: translate(325px, -19px) scale(0.23) rotate(36deg);
		transform: translate(325px, -19px) scale(0.23) rotate(36deg);
	}

	33% {
		-webkit-transform: translate(325px, -13px) scale(0.23) rotate(36deg);
		transform: translate(325px, -13px) scale(0.23) rotate(36deg);
	}

	66% {
		-webkit-transform: translate(325px, -16px) scale(0.23) rotate(36deg);
		transform: translate(325px, -16px) scale(0.23) rotate(36deg);
	}

	100% {
		-webkit-transform: translate(325px, -13px) scale(0.23) rotate(36deg);
		transform: translate(325px, -13px) scale(0.23) rotate(36deg);
	}
}

@keyframes animatedFinalPin {
	0% {
		-webkit-transform: translate(325px, -19px) scale(0.23) rotate(36deg);
		transform: translate(325px, -19px) scale(0.23) rotate(36deg);
	}

	33% {
		-webkit-transform: translate(325px, -13px) scale(0.23) rotate(36deg);
		transform: translate(325px, -13px) scale(0.23) rotate(36deg);
	}

	66% {
		-webkit-transform: translate(325px, -16px) scale(0.23) rotate(36deg);
		transform: translate(325px, -16px) scale(0.23) rotate(36deg);
	}

	100% {
		-webkit-transform: translate(325px, -13px) scale(0.23) rotate(36deg);
		transform: translate(325px, -13px) scale(0.23) rotate(36deg);
	}
}
/* Firefox Final Pin Animation */
@-webkit-keyframes animatedFinalPinFirefox {
	0% {
		-webkit-transform: translate(193px, -16px) scale(0.23) rotate(36deg);
		transform: translate(193px, -16px) scale(0.23) rotate(36deg);
	}

	33% {
		-webkit-transform: translate(193px, -10px) scale(0.23) rotate(36deg);
		transform: translate(193px, -10px) scale(0.23) rotate(36deg);
	}

	66% {
		-webkit-transform: translate(193px, -13px) scale(0.23) rotate(36deg);
		transform: translate(193px, -13px) scale(0.23) rotate(36deg);
	}

	100% {
		-webkit-transform: translate(193px, -10px) scale(0.23) rotate(36deg);
		transform: translate(193px, -10px) scale(0.23) rotate(36deg);
	}
}

@keyframes animatedFinalPinFirefox {
	0% {
		-webkit-transform: translate(193px, -16px) scale(0.23) rotate(36deg);
		transform: translate(193px, -16px) scale(0.23) rotate(36deg);
	}

	33% {
		-webkit-transform: translate(193px, -10px) scale(0.23) rotate(36deg);
		transform: translate(193px, -10px) scale(0.23) rotate(36deg);
	}

	66% {
		-webkit-transform: translate(193px, -13px) scale(0.23) rotate(36deg);
		transform: translate(193px, -13px) scale(0.23) rotate(36deg);
	}

	100% {
		-webkit-transform: translate(193px, -10px) scale(0.23) rotate(36deg);
		transform: translate(193px, -10px) scale(0.23) rotate(36deg);
	}
}
/* Home Concept */
.home-concept {
	background: transparent url(../img/home-concept.png) no-repeat center 0;
	width: 100%;
	overflow: hidden;
}

.home-concept strong {
	display: block;
	font-family: "Shadows Into Light", cursive;
	font-size: 2.1em;
	font-weight: normal !important;
	position: relative;
	margin-top: 30px;
}

.home-concept .row {
	position: relative;
}

.home-concept .process-image {
	background: transparent url(../img/home-concept-item.png) no-repeat 0 0;
	width: 160px;
	margin: 135px auto 0 auto;
	padding-bottom: 50px;
	position: relative;
	z-index: 1;
}

.home-concept .process-image img {
	border-radius: 150px;
	margin: 7px 8px;
	width: auto;
	height: auto;
	max-width: 145px;
	max-height: 145px;
}

.home-concept .our-work {
	margin-top: 52px;
	font-size: 2.6em;
}

.home-concept .project-image {
	background: transparent url(../img/home-concept-item.png) no-repeat 100% 0;
	width: 350px;
	margin: 15px 0 0 -30px;
	padding-bottom: 45px;
	position: relative;
	z-index: 1;
}

.home-concept .sun {
	background: transparent url(../img/home-concept-icons.png) no-repeat 0 0;
	width: 60px;
	height: 56px;
	display: block;
	position: absolute;
	left: 10%;
	top: 35px;
}

.home-concept .cloud {
	background: transparent url(../img/home-concept-icons.png) no-repeat 100% 0;
	width: 116px;
	height: 56px;
	display: block;
	position: absolute;
	left: 57%;
	top: 35px;
}

/* Responsive */
@media (max-width: 991px) {
	.home-concept {
		background-image: none;
	}

	.home-concept .project-image {
		margin: 20px auto 0 auto;
	}

	.home-concept .process-image {
		margin-top: 0;
		padding-bottom: 25px;
	}
}

@media (max-width: 479px) {
	.home-concept .project-image {
		background-position: 0 0;
		margin-left: auto;
		max-width: 160px;
	}

	.home-concept .fc-slideshow {
		max-width: 145px;
		height: 145px;
	}

	.home-concept .fc-slideshow img {
		max-height: 145px;
		max-width: 145px;
	}
}
/* Page 404 */
.page-not-found {
	padding: 50px 0;
}

.page-not-found h2 {
	font-size: 140px;
	font-weight: 600;
	letter-spacing: -10px;
	line-height: 140px;
}

.page-not-found h4 {
	color: #777;
}

.page-not-found p {
	font-size: 1.4em;
	line-height: 36px;
}

/* Responsive */
@media (max-width: 575px) {
	.page-not-found {
		padding: 0;
	}

	.page-not-found h2 {
		font-size: 100px;
		letter-spacing: 0;
		line-height: 100px;
	}
}
/* Position */
.p-relative {
	position: relative !important;
}

.p-absolute {
	position: absolute !important;
}

.p-fixed {
	position: fixed !important;
}

.p-static {
	position: static !important;
}

.absolute-y-center {
	position: absolute;
	top: 50%;
	-webkit-transform: translate3d(0, -50%, 0);
	transform: translate3d(0, -50%, 0);
}

/* Pull */
.pull-none {
	float: none !important;
}

.pull-left {
	float: left !important;
}

.pull-right {
	float: right !important;
}

@media (max-width: 767px) {
	.pull-xs-left {
		float: left;
	}

	.pull-xs-right {
		float: right;
	}
}

@media (min-width: 768px) and (max-width: 991px) {
	.pull-sm-left {
		float: left;
	}

	.pull-sm-right {
		float: right;
	}
}

@media (min-width: 992px) and (max-width: 1199px) {
	.pull-md-left {
		float: left;
	}

	.pull-md-right {
		float: right;
	}
}

@media (min-width: 1200px) {
	.pull-lg-left {
		float: left;
	}

	.pull-lg-right {
		float: right;
	}
}
/* Inverted */
.inverted {
	color: #FFF;
	display: inline-block;
	padding-left: 10px;
	padding-right: 10px;
}

h1 .inverted {
	padding-left: 10px;
	padding-right: 10px;
}

h2 .inverted {
	padding-left: 7px;
	padding-right: 7px;
}

h3 .inverted {
	padding-left: 2px;
	padding-right: 2px;
}

h4 .inverted {
	padding-left: 4px;
	padding-right: 4px;
}

h5 .inverted {
	padding-left: 2px;
	padding-right: 2px;
}

h6 .inverted {
	padding-left: 2px;
	padding-right: 2px;
}

/* Letter Spacing */
.negative-ls-1 {
	letter-spacing: -1px;
}

.negative-ls-2 {
	letter-spacing: -2px;
}

.negative-ls-3 {
	letter-spacing: -2.5px;
}

.positive-ls-1 {
	letter-spacing: 1px;
}

.positive-ls-2 {
	letter-spacing: 2px;
}

.positive-ls-3 {
	letter-spacing: 2.5px;
}

/* Cursor */
.cur-pointer {
	cursor: pointer;
}

/* Font Size */
.text-xs {
	font-size: 16px !important;
	font-size: 1rem !important;
}

.text-sm {
	font-size: 20.8px !important;
	font-size: 1.3rem !important;
}

.text-md {
	font-size: 25.6px !important;
	font-size: 1.6rem !important;
}

.text-lg {
	font-size: 30.4px !important;
	font-size: 1.9rem !important;
}

.text-xl {
	font-size: 35.2px !important;
	font-size: 2.2rem !important;
}

.text-1 {
	font-size: .8em !important;
}

.text-2 {
	font-size: .9em !important;
}

.text-3 {
	font-size: 1em !important;
}

.text-4 {
	font-size: 1.1em !important;
}

.text-5 {
	font-size: 1.50em !important;
}

.text-6 {
	font-size: 1.80em !important;
}

.text-7 {
	font-size: 2em !important;
}

.text-8 {
	font-size: 2.30em !important;
}

.text-9 {
	font-size: 2.50em !important;
}

.text-10 {
	font-size: 2.75em !important;
}

/* Line Height */
.line-height-xs {
	line-height: 16px !important;
}

.line-height-sm {
	line-height: 20px !important;
}

.line-height-md {
	line-height: 24px !important;
}

.line-height-lg {
	line-height: 28px !important;
}

.line-height-xl {
	line-height: 32px !important;
}

/* Opacity */
.opacity-0 {
	opacity: 0 !important;
}

.opacity-1 {
	opacity: 0.1 !important;
}

.opacity-2 {
	opacity: 0.2 !important;
}

.opacity-3 {
	opacity: 0.3 !important;
}

.opacity-4 {
	opacity: 0.4 !important;
}

.opacity-5 {
	opacity: 0.5 !important;
}

.opacity-6 {
	opacity: 0.6 !important;
}

.opacity-7 {
	opacity: 0.7 !important;
}

.opacity-8 {
	opacity: 0.8 !important;
}

.opacity-9 {
	opacity: 0.9 !important;
}

.opacity-10 {
	opacity: 1 !important;
}

/* Outline */
.outline-none {
	outline: 0 !important;
}

/* Text Decoration */
.text-decoration-none {
	text-decoration: none !important;
}

/* Text Transform */
.text-uppercase {
	text-transform: uppercase !important;
}

.text-lowercase {
	text-transform: lowercase !important;
}

.text-capitalize {
	text-transform: capitalize !important;
}

.text-transform-none {
	text-transform: none !important;
}

/* States */
.text-muted {
	color: #999 !important;
}

html.dark .text-muted {
	color: #505461 !important;
}

/* Overflow */
.overflow-visible {
	overflow: visible !important;
}

.overflow-hidden {
	overflow: hidden !important;
}

/* Colors */
.text-dark {
	color: #1d2127 !important;
}

.text-light {
	color: #FFF !important;
}

/* Weights */
.font-weight-light {
	font-weight: 300 !important;
}

.font-weight-normal {
	font-weight: 400 !important;
}

.font-weight-semibold {
	font-weight: 600 !important;
}

.font-weight-bold, b, strong {
	font-weight: 700 !important;
}

.font-weight-extra-bold {
	font-weight: 900 !important;
}

/* Borders */
.no-borders {
	border: none !important;
}

.rounded {
	border-radius: 5px !important;
}

.b-thin {
	border-width: 3px !important;
}

.b-normal {
	border-width: 5px !important;
}

.b-thick {
	border-width: 7px !important;
}

/* General Helpers */
.ib {
	display: inline-block;
	vertical-align: top;
}

.va-middle {
	vertical-align: middle;
}

.ws-nowrap {
	white-space: nowrap;
}

.ws-normal {
	white-space: normal;
}

/* #Footer */
#footer {
	background: #0e0e0e;
	border-top: 4px solid #0e0e0e;
	font-size: 0.9em;
	margin-top: 50px;
	padding: 70px 0 0;
	position: relative;
	clear: both;
}

#footer .container .row > div {
	margin-bottom: 25px;
}

#footer .container input:focus {
	-webkit-box-shadow: none;
	box-shadow: none;
}

#footer a:hover {
	color: #CCC;
}

#footer h1, #footer h2, #footer h3, #footer h4, #footer a {
	color: #FFF;
}

#footer h4 {
	font-size: 1.8em;
	font-weight: 200;
}

#footer h5 {
	color: #CCC;
}

#footer ul.nav-list > li a {
	border-bottom: 1px solid rgba(255, 255, 255, 0.1);
	line-height: 20px;
	padding-bottom: 11px;
}

#footer ul.nav-list > li:last-child a {
	border-bottom: 0;
}

#footer ul.nav-list > li a:hover {
	background-color: rgba(255, 255, 255, 0.05);
}

#footer ul.list.icons li {
	margin-bottom: 5px;
}

#footer form {
	opacity: 0.85;
}

#footer form label.error {
	line-height: 16px;
	margin: 5px 0 -5px;
	display: block;
	clear: both;
}

#footer form .alert {
	padding: 6px;
	text-align: center;
}

#footer .footer-ribbon {
	background: #999;
	position: absolute;
	margin: -90px 0 0 14px;
	padding: 10px 20px 6px 20px;
}

#footer .footer-ribbon:before {
	border-right: 10px solid #646464;
	border-top: 16px solid transparent;
	content: "";
	display: block;
	height: 0;
	right: 100%;
	position: absolute;
	top: 0;
	width: 7px;
}

#footer .footer-ribbon span {
	color: #FFF;
	font-size: 1.6em;
	font-family: "Shadows Into Light", cursive;
}

#footer .newsletter form {
	width: 100%;
	max-width: 262px;
}

#footer .alert a {
	color: #222;
}

#footer .alert a:hover {
	color: #666;
}

#footer .twitter .fa, #footer .twitter .fas, #footer .twitter .far, #footer .twitter .fal, #footer .twitter .fab, #footer .twitter .icons {
	clear: both;
	font-size: 1.5em;
	position: relative;
	top: 3px;
}

#footer .twitter .meta {
	color: #999;
	display: block;
	font-size: 0.9em;
	padding-top: 3px;
}

#footer .twitter ul {
	list-style: none;
	margin: 0;
	padding: 0;
}

#footer .twitter ul li {
	padding-bottom: 20px;
}

#footer .twitter ul li:last-child {
	padding-bottom: 0;
}

#footer .twitter-account {
	color: #FFF;
	display: block;
	font-size: 0.9em;
	margin: -15px 0 5px;
	opacity: 0.55;
}

#footer .twitter-account:hover {
	opacity: 1;
}

#footer .contact {
	list-style: none;
	margin: 0;
	padding: 0;
}

#footer .contact i {
	display: block;
	float: left;
	font-size: 1.2em;
	margin: 7px 8px 10px 0;
	position: relative;
	text-align: center;
	top: -2px;
	width: 16px;
}

#footer .contact i.fa-envelope {
	font-size: 1em;
	top: 0;
}

#footer .contact p {
	margin-bottom: 10px;
}

#footer .logo {
	display: block;
}

#footer .logo.mb-5 {
	margin-bottom: 12px;
}

#footer .footer-copyright {
	background: #060606;
	border-top: 4px solid #060606;
	margin-top: 40px;
	padding: 30px 0 10px;
}

#footer .footer-copyright nav {
	float: right;
}

#footer .footer-copyright nav ul {
	list-style: none;
	margin: 0;
	padding: 0;
}

#footer .footer-copyright nav ul li {
	border-left: 1px solid #505050;
	display: inline-block;
	line-height: 12px;
	margin: 0;
	padding: 0 8px;
}

#footer .footer-copyright nav ul li:first-child {
	border: medium none;
	padding-left: 0;
}

#footer .footer-copyright p {
	color: #555;
	margin: 0;
	padding: 0;
}

#footer .phone {
	color: #FFF;
	font-size: 26px;
	font-weight: bold;
	display: block;
	padding-bottom: 15px;
	margin-top: -5px;
}

#footer .narrow-phone {
	text-align: right;
	margin: -2px 0 0 0;
}

#footer .narrow-phone .phone {
	font-size: 16px;
	padding-bottom: 0;
	margin-top: -13px;
}

#footer .recent-work {
	list-style: none;
	padding: 0;
	margin: 0;
}

#footer .recent-work li {
	float: left;
	width: 30%;
	margin: 10px 3% 0 0;
}

#footer .recent-work img {
	border: 0;
	width: 100%;
	filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#grayscale");
	filter: gray;
	-webkit-filter: grayscale(100%);
}

#footer .recent-work .img-thumbnail-hover-icon {
	border: 0;
}

#footer .recent-work .img-thumbnail-hover-icon:after {
	font-size: 22px;
	margin-left: -2px;
}

#footer .view-more-recent-work {
	padding: 12px;
}

#footer.short {
	padding-top: 50px;
}

#footer.short .footer-copyright {
	background: transparent;
	border: 0;
	padding-top: 0;
	margin-top: 0;
}

#footer.light {
	padding-top: 60px;
	background: #FFF;
	border-top: 1px solid #DDD;
}

#footer.light .footer-ribbon {
	margin-top: -76px;
	margin-left: 14px;
}

#footer.light .footer-copyright {
	margin-top: 15px;
	background: #FFF;
	border-top: 1px solid #DDD;
}

#footer.light .footer-copyright p {
	color: #777;
}

#footer.light .phone,
#footer.light .twitter-account {
	color: #777;
}

#footer.light ul.nav-pills > li > a.active {
	color: #FFF;
}

#footer.narrow {
	padding-top: 30px;
}

#footer.narrow .footer-copyright {
	margin-top: 0;
}

#footer.narrow .nav > li > a {
	padding: 5px 12px;
}

#footer.color {
	color: #FFF;
}

#footer.color p {
	color: #FFF;
}

#footer.color .footer-copyright nav ul li {
	border-left-color: #FFF;
}

#footer.footer-reveal {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	z-index: -10;
}

/* Responsive */
@media (max-width: 991px) {
	#footer .phone {
		font-size: 20px;
	}
}

@media (max-width: 767px) {
	/* Footer */
	#footer .phone {
		font-size: 20px;
	}
}
/* Dark Version */
html.dark body {
	background-color: #1d2127;
}

html.dark.boxed .body {
	background-color: #1d2127;
}

html.dark #header .header-top {
	background: #282d36;
	border-bottom-color: #2e353e;
}

html.dark #header .header-search .form-control {
	background: #21262d;
}

html.dark #header .header-body {
	background: #1d2127;
	border-top-color: #2e353e;
}

html.dark #header .header-nav-bar {
	background-color: #21262d;
}

html.dark #header .header-nav-top .nav > li > a:hover, html.dark #header .header-nav-top .nav > li > a a:focus {
	background-color: #21262d;
}

html.dark #header .header-nav-top .dropdown-menu {
	background: #21262d;
	border-color: #21262d;
}

html.dark #header .header-nav-top .dropdown-menu a:hover, html.dark #header .header-nav-top .dropdown-menu a:focus {
	background: #282d36 !important;
}

html.dark.sticky-header-active #header .header-body {
	border-bottom-color: #2e353e;
}

html.dark #header.header-transparent .header-body {
	background: transparent;
}

html.dark #header.header-transparent .header-body:before {
	background-color: #1d2127;
	border-bottom-color: #2e353e;
}

html.dark #header.header-semi-transparent .header-body {
	background: transparent;
}

html.dark #header.header-semi-transparent .header-body:before {
	background-color: #1d2127;
	border-bottom-color: #2e353e;
}

html.dark #header.header-semi-transparent-light .header-body {
	background: transparent;
}

html.dark #header.header-semi-transparent-light .header-body:before {
	background-color: #1d2127;
	border-bottom-color: #2e353e;
}

html.dark h1, html.dark h2, html.dark h3, html.dark h4, html.dark h5, html.dark h6 {
	color: #FFF;
}

html.dark .alert h1, html.dark .alert h2, html.dark .alert h3, html.dark .alert h4, html.dark .alert h5, html.dark .alert h6 {
	color: #111;
}

html.dark blockquote {
	border-color: #282d36;
}

html.dark section.featured {
	background-color: #191c21;
	border-top-color: #2e353e;
}

html.dark section.section {
	background-color: #21262d;
	border-top-color: #282d36;
}

html.dark .dropdown-menu > li a {
	color: #FFF;
}

html.dark .dropdown-menu > li a:hover, html.dark .dropdown-menu > li a:focus {
	background-color: #2e353e;
}

html.dark .tabs .nav-tabs li a,
html.dark .tabs .nav-tabs li a:focus {
	border-top-color: #282d36;
	border-left-color: #282d36;
	border-right-color: #282d36;
	background: #282d36;
}

html.dark .tabs .nav-tabs li a:hover {
	border-top-color: #808697;
}

html.dark .tabs .nav-tabs li.active a,
html.dark .tabs .nav-tabs li.active a:hover,
html.dark .tabs .nav-tabs li.active a:focus {
	border-top-color: #808697;
}

html.dark .tabs .nav-tabs.nav-justified {
	border-left-width: 0;
	border-right-width: 0;
	border-left-color: transparent;
	border-right-color: transparent;
}

html.dark .tabs .nav-tabs.nav-justified li a,
html.dark .tabs .nav-tabs.nav-justified li a:hover,
html.dark .tabs .nav-tabs.nav-justified li a:focus {
	border-bottom-color: #282d36;
}

html.dark .tabs.tabs-left .nav-tabs > li a, html.dark .tabs.tabs-right .nav-tabs > li a {
	background: #282d36;
	border-left-color: #282d36;
	border-right-color: #282d36;
}

html.dark .tabs.tabs-left .nav-tabs > li:last-child a, html.dark .tabs.tabs-right .nav-tabs > li:last-child a {
	border-bottom-color: #282d36;
}

html.dark .tabs .nav-tabs {
	border-color: #282d36;
}

html.dark .tabs .nav-tabs li.active a,
html.dark .tabs .nav-tabs li.active a:hover,
html.dark .tabs .nav-tabs li.active a:focus,
html.dark .tabs .nav-tabs.nav-justified li.active a,
html.dark .tabs .nav-tabs.nav-justified li.active a:hover,
html.dark .tabs .nav-tabs.nav-justified li.active a:focus {
	background: #2e353e;
	border-left-color: #2e353e;
	border-right-color: #2e353e;
}

html.dark .tabs .nav-tabs.nav-justified li.active a {
	border-bottom-color: #2e353e;
}

html.dark .tabs.tabs-vertical {
	border-top-color: #2e353e;
}

html.dark .tabs.tabs-bottom .nav-tabs li a,
html.dark .tabs.tabs-bottom .nav-tabs li a:focus {
	border-bottom-color: #282d36;
	border-top-color: #2e353e;
}

html.dark .tabs.tabs-bottom .nav-tabs li a:hover {
	border-bottom-color: #808697;
	border-top-color: #2e353e;
}

html.dark .tabs.tabs-bottom .nav-tabs li.active a,
html.dark .tabs.tabs-bottom .nav-tabs li.active a:hover,
html.dark .tabs.tabs-bottom .nav-tabs li.active a:focus {
	border-bottom-color: #808697;
	border-top-color: #2e353e;
}

html.dark .tabs .tab-content {
	background: #2e353e;
	border-color: #2e353e;
}

html.dark .tabs-primary.tabs-bottom .nav-tabs li a,
html.dark .tabs-primary.tabs-bottom .nav-tabs li a:hover,
html.dark .tabs-primary.tabs-bottom .nav-tabs li a:focus, html.dark .tabs-primary.tabs-bottom .nav-tabs.nav-justified li a,
html.dark .tabs-primary.tabs-bottom .nav-tabs.nav-justified li a:hover,
html.dark .tabs-primary.tabs-bottom .nav-tabs.nav-justified li a:focus {
	border-top-color: #2e353e !important;
}

html.dark .nav-tabs li.active a,
html.dark .nav-tabs li.active a:hover,
html.dark .nav-tabs li.active a:focus,
html.dark .nav-tabs li a {
	color: #808697;
}

html.dark .tab-content {
	background: #2e353e;
	border-color: #1d2127;
}

html.dark .tabs-simple .tab-content,
html.dark .tabs-simple .nav-tabs li a,
html.dark .tabs-simple .nav-tabs li.active a {
	background: transparent !important;
}

html.dark .nav > li > a:hover,
html.dark .nav > li > a:focus {
	background-color: #2e353e;
}

html.dark .call-to-action.call-to-action-default {
	background: #282d36;
}

html.dark .call-to-action.call-to-action-dark {
	background: #2e353e;
}

html.dark .call-to-action.with-borders {
	border-top-color: #2e353e;
	border-bottom-color: #2e353e;
	border-left-color: #282d36;
	border-right-color: #282d36;
}

html.dark .call-to-action.with-full-borders {
	border-color: #2e353e;
}

html.dark .call-to-action.featured {
	background: -webkit-gradient(linear, left top, left bottom, color-stop(1%, #282d36), color-stop(98%, #2e353e)) repeat scroll 0 0 rgba(0, 0, 0, 0);
	background: linear-gradient(to bottom, #282d36 1%, #2e353e 98%) repeat scroll 0 0 rgba(0, 0, 0, 0);
	border-bottom-color: #2e353e;
	border-left-color: #282d36;
	border-right-color: #282d36;
}

html.dark .call-to-action.call-to-action-in-footer:before {
	border-top-color: #1d2127;
}

html.dark .counters.with-borders .counter {
	border-top: 1px solid #2e353e;
	border-bottom: 1px solid #2e353e;
	border-left: 1px solid #282d36;
	border-right: 1px solid #282d36;
}

html.dark .counters.counters-text-dark .counter {
	color: #FFF !important;
}

html.dark .counters.counters-text-dark .counter .fa, html.dark .counters.counters-text-dark .counter .fas, html.dark .counters.counters-text-dark .counter .far, html.dark .counters.counters-text-dark .counter .fal, html.dark .counters.counters-text-dark .counter .fab, html.dark .counters.counters-text-dark .counter .icons, html.dark .counters.counters-text-dark .counter strong, html.dark .counters.counters-text-dark .counter label {
	color: #FFF !important;
}

html.dark section.timeline:after {
	background: #505050;
	background: -webkit-gradient(linear, left top, left bottom, from(rgba(80, 80, 80, 0)), color-stop(8%, #505050), color-stop(92%, #505050), to(rgba(80, 80, 80, 0)));
	background: linear-gradient(to bottom, rgba(80, 80, 80, 0) 0%, #505050 8%, #505050 92%, rgba(80, 80, 80, 0) 100%);
}

html.dark section.timeline .timeline-date {
	background-color: #282d36;
	border-color: #282d36;
	text-shadow: none;
}

html.dark section.timeline .timeline-title {
	background: #282d36;
}

html.dark section.timeline .timeline-box {
	border-color: #282d36;
	background: #282d36;
}

html.dark section.timeline .timeline-box.left:before {
	-webkit-box-shadow: 0 0 0 3px #282d36, 0 0 0 6px #282d36;
	box-shadow: 0 0 0 3px #282d36, 0 0 0 6px #282d36;
}

html.dark section.timeline .timeline-box.left:after {
	background: #282d36;
	border-right-color: #282d36;
	border-top-color: #282d36;
}

html.dark section.timeline .timeline-box.right:before {
	-webkit-box-shadow: 0 0 0 3px #282d36, 0 0 0 6px #282d36;
	box-shadow: 0 0 0 3px #282d36, 0 0 0 6px #282d36;
}

html.dark section.timeline .timeline-box.right:after {
	background: #282d36;
	border-left-color: #282d36;
	border-bottom-color: #282d36;
}

html.dark .form-control {
	background-color: #282d36;
	border-color: #282d36;
}

html.dark .btn-light {
	background-color: #282d36;
	border-color: #282d36;
	color: #FFF;
}

html.dark .btn.disabled, html.dark .btn.disabled:hover, html.dark .btn.disabled:active, html.dark .btn.disabled:focus,
html.dark .btn[disabled],
html.dark .btn[disabled]:hover,
html.dark .btn[disabled]:active,
html.dark .btn[disabled]:focus {
	border-color: #282d36 !important;
}

html.dark .pagination > li > a,
html.dark .pagination > li > span {
	background-color: #282d36;
	border-color: #242830;
}

html.dark .theme-default .nivoSlider {
	background-color: #1d2127;
}

html.dark section.section-custom-map {
	background: transparent url(../img/map-dark.png) center 0 no-repeat;
}

html.dark section.section-custom-map section.section {
	border-top-color: rgba(40, 45, 54, 0.8);
	background: rgba(33, 38, 45, 0.8);
}

html.dark .home-concept {
	background-image: url(../img/home-concept-dark.png);
}

html.dark .home-concept .process-image {
	background-image: url(../img/home-concept-item-dark.png);
}

html.dark .home-concept .project-image {
	background-image: url(../img/home-concept-item-dark.png);
}

html.dark .home-concept .sun {
	background-image: url(../img/home-concept-icons-dark.png);
}

html.dark .home-concept .cloud {
	background-image: url(../img/home-concept-icons-dark.png);
}

html.dark .page-header {
	border-bottom-color: #191c21;
}

html.dark .page-header-light {
	background-color: #2e353e;
}

html.dark .accordion .card {
	background-color: #282d36;
}

html.dark .accordion .card-default {
	border-color: #282d36;
}

html.dark .accordion .card-header {
	background-color: #2e353e;
}

html.dark .accordion .form-control {
	background-color: #21262d;
	border-color: #21262d;
}

html.dark .toggle > label {
	background-color: #2e353e;
}

html.dark .toggle.toggle-simple .toggle > label {
	color: #FFF;
}

html.dark .featured-box {
	background: #2e353e;
	border-left-color: #2e353e;
	border-right-color: #2e353e;
	border-bottom-color: #2e353e;
}

html.dark .featured-box.secondary h4 {
	color: #FFF;
}

html.dark .featured-boxes-flat .featured-box .box-content {
	background: #2e353e;
}

html.dark .featured-boxes-style-5 .featured-box .box-content h4, html.dark .featured-boxes-style-6 .featured-box .box-content h4, html.dark .featured-boxes-style-7 .featured-box .box-content h4 {
	color: #FFF;
}

html.dark .featured-boxes-style-2 .featured-box {
	background: transparent;
}

html.dark .featured-boxes-style-3 .featured-box .icon-featured {
	background: #1d2127;
}

html.dark .featured-boxes-style-4 .featured-box {
	background: transparent;
}

html.dark .featured-boxes-style-5 .featured-box {
	background: transparent;
}

html.dark .featured-boxes-style-5 .featured-box .icon-featured {
	background: #282d36;
	border-color: #2e353e;
}

html.dark .featured-boxes-style-6 .featured-box {
	background: transparent;
}

html.dark .featured-boxes-style-6 .featured-box .icon-featured {
	background: #282d36;
	border-color: #2e353e;
}

html.dark .featured-boxes-style-6 .featured-box .icon-featured:after {
	border-color: #2e353e;
}

html.dark .featured-boxes-style-7 .featured-box {
	background: transparent;
}

html.dark .featured-boxes-style-7 .featured-box .icon-featured {
	background: #282d36;
	border-color: #2e353e;
}

html.dark .featured-boxes-style-7 .featured-box .icon-featured:after {
	-webkit-box-shadow: 3px 3px #161a1e;
	box-shadow: 3px 3px #161a1e;
}

html.dark .featured-boxes-style-8 .featured-box .icon-featured {
	background: #2e353e;
}

html.dark .featured-box-effect-1 .icon-featured:after {
	-webkit-box-shadow: 0 0 0 3px #2e353e;
	box-shadow: 0 0 0 3px #2e353e;
}

html.dark .feature-box.feature-box-style-2 h4, html.dark .feature-box.feature-box-style-3 h4, html.dark .feature-box.feature-box-style-4 h4 {
	color: #FFF;
}

html.dark .feature-box.feature-box-style-6 .feature-box-icon {
	border-color: #2e353e;
}

html.dark .feature-box.feature-box-style-6 .feature-box-icon:after {
	border-color: #2e353e;
}

html.dark .owl-dots button.owl-dot span {
	background: #2e353e;
}

html.dark .owl-carousel.top-border {
	border-top-color: #3F4247;
}

html.dark .progress {
	background: #2e353e;
}

html.dark .arrow {
	background-image: url(../img/arrows-dark.png);
}

html.dark .thumbnail,
html.dark .img-thumbnail,
html.dark .thumb-info {
	background-color: #282d36;
	border-color: #282d36;
}

html.dark .thumb-info .thumb-info-wrapper:after {
	background-color: rgba(29, 33, 39, 0.9);
}

html.dark .thumb-info-social-icons {
	border-top-color: #373f4a;
}

html.dark ul.nav-list > li a {
	border-bottom-color: #282d36;
}

html.dark ul.nav-list > li a:hover {
	background-color: #282d36;
}

html.dark .content-grid .content-grid-item:before {
	border-left-color: #2e353e;
}

html.dark .content-grid .content-grid-item:after {
	border-bottom-color: #2e353e;
}

html.dark .content-grid.content-grid-dashed .content-grid-item:before {
	border-left-color: #2e353e;
}

html.dark .content-grid.content-grid-dashed .content-grid-item:after {
	border-bottom-color: #2e353e;
}

html.dark .testimonial .testimonial-author strong {
	color: #FFF;
}

html.dark .testimonial.testimonial-style-3 blockquote {
	background: #2e353e !important;
}

html.dark .testimonial.testimonial-style-3 .testimonial-arrow-down {
	border-top-color: #2e353e !important;
}

html.dark .testimonial.testimonial-style-4 {
	border-color: #2e353e !important;
}

html.dark .testimonial.testimonial-style-5 .testimonial-author {
	border-top-color: #2e353e !important;
}

html.dark .popover {
	background-color: #2e353e;
	border: 1px solid #282d36;
}

html.dark .popover.top > .arrow {
	border-top-color: #282d36;
}

html.dark .popover.top > .arrow:after {
	border-top-color: #2e353e;
}

html.dark .popover.right > .arrow {
	border-right-color: #282d36;
}

html.dark .popover.right > .arrow:after {
	border-right-color: #2e353e;
}

html.dark .popover.bottom > .arrow {
	border-bottom-color: #282d36;
}

html.dark .popover.bottom > .arrow:after {
	border-bottom-color: #2e353e;
}

html.dark .popover.left > .arrow {
	border-left-color: #282d36;
}

html.dark .popover.left > .arrow:after {
	border-left-color: #2e353e;
}

html.dark .popover-title {
	background-color: #282d36;
	border-bottom: #2e353e;
}

html.dark .page-header {
	border-bottom-color: #282d36;
}

html.dark .table > thead > tr > th,
html.dark .table > tbody > tr > th,
html.dark .table > tfoot > tr > th,
html.dark .table > thead > tr > td,
html.dark .table > tbody > tr > td,
html.dark .table > tfoot > tr > td,
html.dark .table-bordered {
	border-color: #282d36;
}

html.dark .table-striped > tbody > tr:nth-child(2n+1) > td,
html.dark .table-striped > tbody > tr:nth-child(2n+1) > th {
	background-color: #2e353e;
}

html.dark pre {
	background-color: #282d36;
	border-color: #282d36;
	color: #777;
}

html.dark .show-grid [class*="col-lg-"] .show-grid-block {
	background-color: #282d36;
	border-color: #282d36;
}

html.dark .google-map-borders,
html.dark .embed-responsive-borders {
	border-color: #2e353e;
}

html.dark .alert.alert-default {
	border-color: #2e353e;
	background-color: #282d36;
}

html.dark hr {
	background-image: -webkit-gradient(linear, right top, left top, from(transparent), color-stop(#3F4247), to(transparent));
	background-image: linear-gradient(to left, transparent, #3F4247, transparent);
}

html.dark hr.light {
	background-image: -webkit-gradient(linear, right top, left top, from(transparent), color-stop(#3F4247), to(transparent));
	background-image: linear-gradient(to left, transparent, #3F4247, transparent);
}

html.dark hr.solid {
	background: #3F4247;
}

html.dark .divider {
	background-image: -webkit-gradient(linear, right top, left top, from(transparent), color-stop(#3F4247), to(transparent));
	background-image: linear-gradient(to left, transparent, #3F4247, transparent);
}

html.dark .divider .fa, html.dark .divider .fas, html.dark .divider .far, html.dark .divider .fal, html.dark .divider .fab, html.dark .divider .icons {
	background: #1d2127;
}

html.dark .divider.divider-solid {
	background: #3F4247;
}

html.dark .divider.divider-style-2 .fa, html.dark .divider.divider-style-2 .fas, html.dark .divider.divider-style-2 .far, html.dark .divider.divider-style-2 .fal, html.dark .divider.divider-style-2 .fab, html.dark .divider.divider-style-2 .icons {
	background: #21262d;
}

html.dark .divider.divider-style-3 .fa, html.dark .divider.divider-style-3 .fas, html.dark .divider.divider-style-3 .far, html.dark .divider.divider-style-3 .fal, html.dark .divider.divider-style-3 .fab, html.dark .divider.divider-style-3 .icons {
	border-color: #3F4247;
}

html.dark .divider.divider-style-4 .fa, html.dark .divider.divider-style-4 .fas, html.dark .divider.divider-style-4 .far, html.dark .divider.divider-style-4 .fal, html.dark .divider.divider-style-4 .fab, html.dark .divider.divider-style-4 .icons {
	border-color: #3F4247;
}

html.dark .divider.divider-style-4 .fa:after, html.dark .divider.divider-style-4 .fas:after, html.dark .divider.divider-style-4 .far:after, html.dark .divider.divider-style-4 .fal:after, html.dark .divider.divider-style-4 .fab:after, html.dark .divider.divider-style-4 .icons:after {
	border-color: #21262d;
}

html.dark .divider.divider-small {
	background: transparent;
}

html.dark .divider.divider-small hr {
	background: #3F4247;
}

html.dark .divider.divider-small.divider-light hr {
	background: #3F4247;
}

html.dark hr.dashed:after, html.dark .divider.dashed:after {
	border-color: #3F4247;
}

html.dark .heading.heading-bottom-border h1 {
	border-bottom-color: #3f4247;
}

html.dark .heading.heading-bottom-border h2, html.dark .heading.heading-bottom-border h3 {
	border-bottom-color: #3f4247;
}

html.dark .heading.heading-bottom-border h4, html.dark .heading.heading-bottom-border h5, html.dark .heading.heading-bottom-border h6 {
	border-bottom-color: #3f4247;
}

html.dark .heading.heading-bottom-double-border h1, html.dark .heading.heading-bottom-double-border h2, html.dark .heading.heading-bottom-double-border h3 {
	border-bottom-color: #3f4247;
}

html.dark .heading.heading-bottom-double-border h4, html.dark .heading.heading-bottom-double-border h5, html.dark .heading.heading-bottom-double-border h6 {
	border-bottom-color: #3f4247;
}

html.dark .heading.heading-middle-border:before {
	border-top-color: #3f4247;
}

html.dark .heading.heading-middle-border h1, html.dark .heading.heading-middle-border h2, html.dark .heading.heading-middle-border h3, html.dark .heading.heading-middle-border h4, html.dark .heading.heading-middle-border h5, html.dark .heading.heading-middle-border h6 {
	background: #1d2127;
}

html.dark ul.history li .thumb {
	background-image: url(../img/history-thumb-dark.png);
}

html.dark .recent-posts .date .day {
	background-color: #2e353e;
}

html.dark .blog-posts article {
	border-color: #282d36;
}

html.dark section.featured .recent-posts .date .day,
html.dark article.post .post-date .day {
	background-color: #282d36;
}

html.dark article .post-video,
html.dark article .post-video iframe,
html.dark article .post-audio,
html.dark article .post-audio iframe {
	background-color: #282d36;
	border-color: #282d36;
}

html.dark ul.simple-post-list li {
	border-bottom-color: #21262d;
}

html.dark .post-block {
	border-top-color: #191c21;
}

html.dark ul.comments .comment-block {
	background-color: #282d36;
}

html.dark ul.comments .comment-arrow {
	border-right-color: #282d36;
}

html.dark .pricing-table li {
	border-top-color: #21262d;
}

html.dark .pricing-table h3 {
	background-color: #21262d;
	text-shadow: none;
}

html.dark .pricing-table h3 span {
	background: #282d36;
	border-color: #242830;
	-webkit-box-shadow: 0 5px 20px #242830 inset, 0 3px 0 #282d36 inset;
	box-shadow: 0 5px 20px #242830 inset, 0 3px 0 #282d36 inset;
	color: #777;
}

html.dark .pricing-table .most-popular {
	border-color: #282d36;
}

html.dark .pricing-table .most-popular h3 {
	background-color: #282d36;
	color: #FFF;
	text-shadow: none;
}

html.dark .pricing-table .plan-ribbon {
	background-color: #282d36;
}

html.dark .pricing-table .plan {
	background: #282d36;
	border: 1px solid #282d36;
	color: #777;
	text-shadow: none;
}

html.dark .product-thumb-info {
	background-color: #282d36;
	border-color: transparent;
}

html.dark .shop .quantity .qty {
	background-color: #282d36;
	border-color: transparent;
}

html.dark .shop .quantity .minus,
html.dark .shop .quantity .plus {
	background-color: #21262d;
	border-color: transparent;
}

html.dark .shop table.cart td,
html.dark .shop .cart-totals th,
html.dark .shop .cart-totals td {
	border-color: #282d36;
}

html.dark .dialog {
	background-color: #1d2127;
}

html.dark .modal-content {
	background-color: #1d2127;
}

html.dark .modal-header {
	border-bottom-color: #282d36;
}

html.dark .modal-header h1, html.dark .modal-header h2, html.dark .modal-header h3, html.dark .modal-header h4, html.dark .modal-header h5, html.dark .modal-header h6 {
	color: #777;
}

html.dark .modal-header .close {
	text-shadow: none;
	color: #FFF;
}

html.dark .modal-footer {
	border-top-color: #282d36;
}

html.dark .popup-inline-content,
html.dark .mfp-content .ajax-container {
	background: #1d2127 !important;
}

html.dark .loading-overlay {
	background: #1d2127;
}

html.dark .sort-destination-loader:after {
	background-color: #1d2127;
}

html.dark #footer .newsletter form .btn-light {
	background-color: #21262d;
	border-color: #21262d;
	color: #777;
}

html.dark #footer .newsletter form .form-control {
	border: 0;
}

/* Responsive */
@media (min-width: 992px) {
	html.dark #header .header-nav-main:not(.header-nav-main-light) nav > ul > li.dropdown:not(.dropdown-full-color) .dropdown-menu {
		background: #21262d;
	}

	html.dark #header .header-nav-main:not(.header-nav-main-light) nav > ul > li.dropdown:not(.dropdown-full-color) .dropdown-menu li a {
		border-bottom-color: #2e353e;
	}

	html.dark #header .header-nav-main:not(.header-nav-main-light) nav > ul > li.dropdown:not(.dropdown-full-color) .dropdown-menu li a:hover, html.dark #header .header-nav-main:not(.header-nav-main-light) nav > ul > li.dropdown:not(.dropdown-full-color) .dropdown-menu li a:focus, html.dark #header .header-nav-main:not(.header-nav-main-light) nav > ul > li.dropdown:not(.dropdown-full-color) .dropdown-menu li a.active, html.dark #header .header-nav-main:not(.header-nav-main-light) nav > ul > li.dropdown:not(.dropdown-full-color) .dropdown-menu li a:active {
		background: #2e353e;
	}

	html.dark #header .header-nav-main:not(.header-nav-main-light) nav > ul > li.dropdown-mega:not(.dropdown-full-color) .dropdown-mega-sub-title {
		color: #999;
	}

	html.dark #header .header-nav-main:not(.header-nav-main-light) nav > ul > li.dropdown-mega:not(.dropdown-full-color) .dropdown-mega-sub-nav > li:hover > a {
		background: #2e353e;
	}

	html.dark #header .header-nav-main:not(.header-nav-main-light) a > .thumb-info-preview .thumb-info-wrapper {
		background: #2e353e;
	}

	html.dark #header .header-nav.header-nav-stripe nav > ul > li > a {
		color: #CCC;
	}

	html.dark #header .header-nav.header-nav-stripe nav > ul > li:hover > a {
		color: #FFF;
	}

	html.dark #header .header-nav.header-nav-top-line nav > ul > li > a, html.dark #header .header-nav.header-nav-top-line nav > ul > li:hover > a {
		color: #CCC;
	}

	html.dark #header .header-nav.header-nav-dark-dropdown nav > ul > li > a, html.dark #header .header-nav.header-nav-dark-dropdown nav > ul > li:hover > a {
		color: #CCC;
	}

	html.dark .header-nav-main nav > ul > li.dropdown-mega-signin .dropdown-menu {
		background-color: #1d2127;
	}
}

@media (max-width: 991px) {
	html.dark #header .header-nav-main:before {
		background-color: #1d2127;
	}

	html.dark #header .header-nav-main nav::-webkit-scrollbar-thumb {
		border-color: transparent;
		background: #39404c;
	}

	html.dark #header .header-nav-main nav > ul li {
		border-bottom-color: #2e353e;
	}

	html.dark #header .header-nav-main nav > ul > li.dropdown-mega:not(.dropdown-full-color) .dropdown-mega-sub-title {
		color: #FFF;
	}

	html.dark #header .header-nav-main nav ul li a:active {
		background-color: #2e353e;
		color: #FFF;
	}

	html.dark #header.header-transparent .header-nav-main, html.dark #header.header-semi-transparent .header-nav-main, html.dark #header.header-semi-transparent-light .header-nav-main {
		background-color: #1d2127;
	}

	html.dark .home-concept {
		background-image: none;
	}
}
/* Boxed Layout */
html.boxed body {
	background-color: transparent;
	background-position: 0 0;
	background-repeat: repeat;
}

html.boxed .body {
	position: relative;
	background-color: #FFF;
	border-bottom: 5px solid #1d2127;
	border-radius: 5px;
	border-top: 5px solid #CCC;
	-webkit-box-shadow: 0 0 4px rgba(0, 0, 0, 0.15);
	box-shadow: 0 0 4px rgba(0, 0, 0, 0.15);
	margin: 25px auto;
	max-width: 1200px;
	height: auto;
}

html.boxed #header .header-body {
	border-top-color: transparent;
}

html.boxed .main {
	overflow: hidden;
}

/* Responsive */
@media (min-width: 1200px) {
	html.boxed.sticky-header-active #header .header-body {
		width: 100%;
		max-width: 1200px;
	}

	html.boxed #header.header-transparent .header-body,
	html.boxed #header.header-semi-transparent .header-body,
	html.boxed #header.header-semi-transparent-light .header-body {
		width: auto;
	}
}

@media (min-width: 992px) {
	html.boxed #header .header-top.header-top-colored {
		margin-top: -18px;
		border-radius: 4px 4px 0 0;
	}

	html.boxed #header.header-transparent .header-body,
	html.boxed #header.header-semi-transparent .header-body,
	html.boxed #header.header-semi-transparent-light .header-body {
		position: absolute;
		padding-left: 15px;
		padding-right: 15px;
	}

	html.boxed #header .header-top.header-top-style-2,
	html.boxed #header .header-top.header-top-style-3,
	html.boxed #header .header-top.header-top-style-4 {
		margin-top: -13px;
	}

	html.boxed.sticky-header-active #header .header-body {
		position: fixed !important;
		padding-left: 15px;
		padding-right: 15px;
	}

	html.boxed.sticky-header-active #header .header-nav-bar {
		margin: 0 -15px -9px;
	}
}

@media (max-width: 991px) {
	html.boxed .body {
		margin: 0 auto;
		border-radius: 0;
	}
}

@media (max-width: 767px) {
	/* Boxed */
	html.boxed {
		background: none !important;
	}
}
